import {Box, CircularProgress, debounce, InputAdornment, MenuItem, Select} from '@mui/material';
import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {Plus} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {errorBigIcon, iconNotifyWarningInfo, searchIcon, toastSuccess} from '../../../../assets/icons/icons';
import {Button, Typography} from '../../../../components/StyledComponents';
import {
  EPaymentRequiredType,
  EPlatformsNames,
  ESubscriptionPlans,
  ETrialStatus,
  SubscriptionMockData
} from '../../../../models/consts';
import {IError, IPaymentRequiredPoppup, IPoppupBase, PlatformProps} from '../../../../models/inner-models';
import Platform from '../../../../models/platforms-data';
import {
  backupAllByPlatform,
  backupGitlabSnippets,
  deleteGitlabItem,
  deleteGitlabSnippet,
  fetchGitlabUsers,
  fetchItemsForGitlabUser,
  fetchSnippetsForGitlabUser,
  getAllSubscriptions,
} from '../../../../store/actions';
import {useAppDispatch, useAppSelector} from '../../../../store/hooks';
import {
  getGitlabCanUserBackup,
  getGitlabReposSelector,
  getGitlabTotal,
  getGitlabUsersSelector,
  getIsTestUser,
  gitlabSubscription,
  gitlabTrialHoursLeft,
  gitlabTrialPlanQuantity,
  gitlabTrialQuantityLeft,
  gitlabTrialStatus,
  isGitlabRepoLoadingSelector,
  isGitlabUsersLoadingSelector,
  loadingUser,
} from '../../../../store/selectors';
import {SearchInput} from '../../../../styles/components/MainInputElements';
import getSubInfo from '../../../../utils/platformSubscriptionInfo';
import withRedirectAfterLogout from '../../../../utils/withRedirectAfterLogout';
import '../../style.scss';
import fetchItemsErrorHandler from "../../../../utils/fetchItemsErrorHandler";
import PlatformItemsList from "../../../../components/PlatformComponents/PlatformItemsList/PlatformItemsList";
import GitlabRepoAdd from "./GitlabProjectAddDialog/GitlabProjectAdd";
import PaymentRequiredPoppup from "../../../../components/Dialogs/PaymentRequiredPoppup/PaymentRequiredPoppup";
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../../../utils/popUpTextDecorator";
import {defaultTrialReposMax} from "../../../../constants";
import NoSubscriptionBlock from "../../../../components/Blocks/NoSubscriptionBlock/NoSubscriptionBlock";
import NoBackupsBlock from "../../../../components/Blocks/NoItemsBlock/NoItemsBlock";
import Modal from "../../../../components/Dialogs/Modal/Modal";
import GitlabUserAddEdit from "../GitlabUserAddEditView/GitlabUserAddEdit";
import DeleteItemDialog from "../../../../components/Dialogs/DeleteItemDialog/DeleteItemDialog";
import DatabaseIcoInvert from "../../../../assets/icons/CustomSvg/DatabaseIcoInvert";
import {BadgesBackup, BadgesSubscription, BadgesTrial} from "../../../../components/Badges/Badges";
import CustomTimerTooltip from "../../../../styles/components/CustomTimerTooltip";
import {CustomTooltip} from '../../../../styles/components/CustomTooltip';
import {updateSubscriptionPlanModal} from "../../../../utils/functions/updateSubscriptionPlanModal";
import ExternalStorage from "../../../UserPages/UserSettings/ExternalStorage/ExternalStorage";

const GitlabPlatformSettings: FunctionComponent = () => {
  const {t: translate} = useTranslation();
  const userLoading = useAppSelector(loadingUser);
  const repos = useAppSelector(getGitlabReposSelector);
  const total = useAppSelector(getGitlabTotal);
  const canUserBackup = useAppSelector(getGitlabCanUserBackup);
  const isTestUser = useAppSelector(getIsTestUser);
  const isLoadingRepos = useAppSelector(isGitlabRepoLoadingSelector);
  const isLoadingGitlabUsers = useAppSelector(isGitlabUsersLoadingSelector);
  const trialState = useAppSelector(gitlabTrialStatus);
  const trialDays = useAppSelector(gitlabTrialHoursLeft);
  const trialQuantity = useAppSelector(gitlabTrialQuantityLeft);
  const trialPlanQuantity = useAppSelector(gitlabTrialPlanQuantity);
  const subInfo = useAppSelector(gitlabSubscription);
  const users = useAppSelector(getGitlabUsersSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const platformName = EPlatformsNames.GITLAB;
  const platform = Platform[platformName];
  const [searchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [notification, setNotification] = useState<IPoppupBase>({
    isOpen: false,
  });
  const [paymentPoppup, setPaymentPoppup] = useState<IPaymentRequiredPoppup>({
    isOpen: false,
    platformName,
  });
  const [paymentState, setPaymentState] = useState<EPaymentRequiredType>(EPaymentRequiredType.NON);
  const [buyShowed, setBuyShowed] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showSettings2, setShowSettings2] = useState<boolean>(false);
  const [deleteDate, setDeleteData] = useState<{ id?: string, name?: string }>({});
  const [deleteBulkDate, setDeleteBulkData] = useState<Array<string>>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectList, setSelectList] = useState<Array<string>>([]);
  const [progressItem, setProgressItem] = useState<string>('');
  const [progressEnd, setProgressEnd] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [intervalFunc, setIntervalFunc] = useState<any>();
  const [userCanBackup, setUserCanBackup] = useState<boolean>(false);
  const [isSnip, setIsSnip] = useState<boolean>(localStorage['gitlabType'] === 'snippet');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState(0);

  const clearState = (notPage?: boolean) => {
    setDeleteData({})
    setDeleteBulkData([])
    setSelectList([])
    setSelectAll(false)
    if (!notPage) {
      setPage(0)
    }
  }

  useEffect(() => {
    const isExt = searchParams.get('isCloudStorageConnected');
    const errorMessage = searchParams.get('error_message');

    if (!errorMessage && isExt) {
      navigate(window.location.pathname, { replace: true })
      setShowSettings2(true)
    }
  })

  useEffect(() => {
    setUserCanBackup(canUserBackup)
  }, [canUserBackup])

  const restartBadge = () => {
    if (intervalFunc) {
      clearInterval(intervalFunc)
      setIntervalFunc(null)
    }
    setProgressEnd(false)
    setProgressItem('')
  }

  const [platformInfo, setPlatformInfo] = useState<PlatformProps>(
    {
      platformName: platformName,
      subPlan: SubscriptionMockData[ESubscriptionPlans.NO_SUB],
      items: repos || [],
      filteredItems: repos || [],
      users: users || [],
    },
  );

  useEffect(() => {
    if (!userLoading) {
      const subPlan = getSubInfo(trialState, subInfo, trialDays, platformName, trialQuantity, trialPlanQuantity);

      setPlatformInfo((prev) => ({
        ...prev,
        subPlan,
      }));
    }
  }, [trialState, userLoading, trialDays, subInfo, trialQuantity, trialPlanQuantity]);

  useEffect(() => {
    if (!isLoadingRepos && !isLoadingGitlabUsers) {
      setPlatformInfo((prev) => ({
        ...prev,
        items: repos || [],
        users: users || [],
        filteredItems: repos || [],
      }));

      const filt = repos?.filter(itemB => !!itemB.actionProcess?.length || itemB.status === 'initialized')
      if (filt && filt.length > 1 && !progressItem.length) {
        const isRest = filt.filter(itemB => itemB.actionProcess === 'restoring_process')
        filt.length === isRest?.length ?
          setProgressItem(translate(`notifications.all.actionBadge.many.restore`, {app: 'GitLab'})) :
          !!isRest?.length ?
            setProgressItem(translate(`notifications.all.actionBadge.multi`)) :
            setProgressItem(translate(`notifications.all.actionBadge.many.backup`, {app: 'GitLab'}))
      } else if (filt?.length === 1 && !progressItem.length) {
        setProgressItem(translate(`notifications.all.actionBadge.one.${filt[0].actionProcess || 'backing_up_process'}`, {
          app: 'GitLab',
          item: filt[0].project?.split('::')[0]
        }))
      } else if (!filt?.length && !!progressItem?.length) {
        if (intervalFunc) {
          clearInterval(intervalFunc)
        }
        setProgressEnd(true)
      }
    }
  }, [repos, users, isLoadingRepos, isLoadingGitlabUsers]);

  const fetchData = async (newPage?: number, name?: string) => {
    try {
      setIsLoading(true)
      restartBadge()
      if (!localStorage['gitlabType']) {
        localStorage['gitlabType'] = 'project'
      }
      if (!users) {
        dispatch(fetchGitlabUsers()).unwrap().catch(err => console.log(err));
      }
      await dispatch(isSnip ? fetchSnippetsForGitlabUser({
        page: newPage || 0,
        perPage: 10,
        name
      }) : fetchItemsForGitlabUser({page: newPage || 0, perPage: 10, name})).unwrap()
        // await dispatch(fetchItemsForGitlabUser({page: newPage || 0, perPage: 10})).unwrap()
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
          const error = err as IError;
          fetchItemsErrorHandler(error)
        })
        .finally(() => setIsLoading(false));

    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    if (platformInfo.subPlan.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED) {
      navigate('/app-connector')
    }
    fetchData()
  }, []);


  const debouncedSearch = useCallback(
    debounce((event) => {
      setPage(0)
      fetchData(0, event.toLowerCase())
    }, 1000),
    [],
  );
  const onSearch = useCallback((event) => {
    const val = event.target.value || ''
    setSearchTerm(val);
    debouncedSearch(val)
  }, []);

  useEffect(() => {
    if ((platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && !platformInfo.subPlan.periodHours) ||
      (platformInfo.subPlan.type === ESubscriptionPlans.SUBSCRIPTION && !platformInfo.subPlan.isPaid)) {
      setPaymentState(
        platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && !platformInfo.subPlan.periodHours ?
          EPaymentRequiredType.TRIAL : platformInfo.subPlan.isPaid ?
            EPaymentRequiredType.NON : EPaymentRequiredType.SUB,
      );
    } else {
      setPaymentState(EPaymentRequiredType.NON)
    }
  }, [platformInfo]);

  useEffect(() => {
    if (!isLoadingRepos && trialState === 'expired' && (!subInfo || subInfo?.status !== 'active') && !buyShowed) {
      setBuyShowed(true)
      Swal.fire({
        title: !!subInfo ? translate('notifications.subscription.subscribe_buy') : translate('notifications.subscription.subscribe_buy_trial'),
        text: !!subInfo ? translate('notifications.subscription.subscribe_buy_text') : translate('notifications.subscription.subscribe_buy_text_trial'),
        cancelButtonText: translate('notifications.choices.close'),
        confirmButtonText: translate('notifications.choices.buy_subscription_now'),
        showCancelButton: true,
        imageUrl: errorBigIcon,
      })
        .then((res) => {
          if (res.isConfirmed) {
            localStorage.setItem('checkout-platform', platformName)
            navigate('/checkout');
          }
        })
    }
  }, [isLoadingRepos, subInfo]);

  const addRepos = () => {
    if (paymentState !== EPaymentRequiredType.NON) {
      setPaymentPoppup((prev) => ({
        ...prev,
        isOpen: true,
      }));
    } else {
      setNotification({
        isOpen: true,

      });
    }
  }

  const onConfirmDeleteRepo = async (id, isDelBulk?) => {
    try {
      // setIsLoading(true)
      await dispatch(isSnip ? deleteGitlabSnippet({snippetsIds: [...(isDelBulk ? id : [id])]}) : deleteGitlabItem({ids: [...(isDelBulk ? id : [id])]})).unwrap();
      dispatch(isSnip ? fetchSnippetsForGitlabUser({page: page, perPage: 10}) : fetchItemsForGitlabUser({
        page: page,
        perPage: 10
      })).unwrap()

      dispatch(getAllSubscriptions()).unwrap()
      restartBadge()
      clearState()

      await Swal.fire({
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
        title: translate('notifications.titles.success'),
        text: isDelBulk ?
          translate('notifications.github_repos.successfully_deleted_many') :
          !isSnip?
            translate('notifications.github_repos.successfully_deleted') :
            translate('notifications.github_repos.successfully_deleted_gist')
      });
      // setIsLoading(false)

    } catch (err) {
      const error = err as IError;
      // setIsLoading(false)

      await Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };
  const handleDeleteRepo = async (id, name) => {
    setDeleteData({id, name});
  };

  const handleDeleteBulkBoard = async (arr) => {
    setDeleteBulkData(arr);
  };

  const closeNotification = useCallback(() => {
    setNotification({...notification, isOpen: false});
  }, []);

  const closeTrialPoppup = useCallback(() => {
    setPaymentPoppup({...paymentPoppup, isOpen: false});
  }, []);

  const getRemainingItems = useCallback(() => {
    if (!platformInfo.subPlan) {
      return 0;
    }

    if (platformInfo.subPlan.quantity !== undefined && platformInfo.subPlan.quantity >= 0 && platformInfo.items) {
      const payedItemsLeft = platformInfo.subPlan.quantity;
      return payedItemsLeft > 0 ? payedItemsLeft : 0;
    }

    return defaultTrialReposMax;
  }, [platformInfo]);

  const validationBlock = useCallback(() => {
    if ((platformInfo.subPlan.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED) ||
      (platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && trialState === ETrialStatus.EXPIRED && !repos?.length)) {
      return <NoSubscriptionBlock platformName={platformName}/>;
    }

    if (!users?.length) {
      return <NoBackupsBlock
        action={() => navigate('/app-connector')}
        platformItem={translate('views.bulk_restore.itemName.gitlab.one')}
        actionText={translate('forms.github_add_edit_repo.configure_account')}
      />;
    }

    if (!platformInfo.items?.length) {
      return <NoBackupsBlock
        action={addRepos}
        platformItem={translate('views.bulk_restore.itemName.gitlab.one')}
        actionText={translate(`forms.gitlab_add_edit_project.${isSnip ? 'add_snip_num' : 'add_project_num'}`, {num: ''})}
      />;
    }

    return null;
  }, [trialState, users, platformInfo, paymentState]);

  const backupAllNow = async () => {
    try {
      setIsLoading(true)
      const backResp = isSnip ? await dispatch(backupGitlabSnippets()).unwrap() : await dispatch(backupAllByPlatform('gitlab')).unwrap();
      // await dispatch(fetchItemsForGitlabUser({page: page, perPage: 10})).unwrap()
      // dispatch(fetchItemsForGitlabUser()).unwrap()
      restartBadge()
      if (total === 1) {
        setProgressItem(translate(`notifications.all.actionBadge.one.backing_up_process`, {
          app: 'GitLab',
          item: platformInfo.items?.[0]?.project?.split('::')[0]
        }))
      } else {
        setProgressItem(translate(`notifications.all.actionBadge.many.backup`, {app: 'GitLab'}))
      }

      if (!isTestUser) {
        setUserCanBackup(false)
      }

      await Swal.fire({
        title: translate('notifications.titles.success'),
        text: total > 1 ? translate('notifications.github_repos.multi_start_backup') : translate('notifications.github_repos.single_start_backup'),
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
      setIsLoading(false)
    } catch (err) {
      const error = err as IError;
      setIsLoading(false)

      if (error.code === 'err.user.backup.limit') {
        setUserCanBackup(false)
        await Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          toast: true,
          position: 'top-end',
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true,
          imageUrl: iconNotifyWarningInfo,
          timer: 3000,
        });
      } else {
        await Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: errorBigIcon,
          confirmButtonText: translate('notifications.choices.close'),
        });
      }
    }
  }

  return (
    <div id="platform-content" className='list-view'>
      {!!progressItem?.length && (
        <BadgesBackup progressItem={progressItem} platformName={platformName} isComplete={progressEnd} onClose={() => {
          restartBadge()
        }}/>
      )}
      {trialState === 'active' && trialDays && (
        <BadgesTrial trialDays={trialDays} platformName={platformName}/>
      )}
      {!isLoadingRepos && trialState === 'expired' && (!subInfo || subInfo?.status !== 'active') && (
        <BadgesTrial platformName={platformName}
                     color={!!repos?.filter(i => i.lastBackup !== '-').length ? 'error' : 'error_2'} isSub={!!subInfo}/>
      )}
      {subInfo && subInfo?.isCancel && subInfo?.finishDateInMillisecond && ((subInfo?.finishDateInMillisecond- Date.now())/(24*60*60*1000))<1 &&<BadgesSubscription platformName={platformName}/>
      }
      {/*{!isLoadingRepos && ((trialState === 'active' && trialDays && !trialQuantity) || (subInfo && subInfo.status === 'active' && !subInfo.quantity)) && (*/}
      {/*  <BadgesTrial platformName={platformName} color='warning' isSub={subInfo ? subInfo.status === 'active' : false}/>*/}
      {/*)}*/}

      <div className="header-block">
        <Typography variant="h3" gutterBottom display="inline">
          {translate('forms.github_add_edit_repo.your_items', {platform: platform?.title, itemName: ''})}
        </Typography>

        <div className="search-block">
          <div className="action-button">
            {/*{(trialState === 'active' || subInfo || !!platformInfo?.users?.length) && (*/}
            {/*  <Button variant="outlined" color="primary"*/}
            {/*          onClick={() => setShowSettings(true)}>*/}
            {/*    {translate('forms.github_add_edit_repo.open_setting')}*/}
            {/*  </Button>*/}
            {/*)}*/}
            {(trialState === 'active' || subInfo || !!platformInfo?.users?.length) && !isLoadingGitlabUsers && !!users?.length  && (
              <CustomTooltip
                title={(platformInfo.subPlan?.name !== 'Pro' && platformInfo.subPlan?.name !== 'Trial') ? 'This feature is only available with a Pro Plan subscription' : ''}
              >
                <Button variant="outlined" color="primary"
                        disabled={platformInfo.subPlan?.name !== 'Pro' && platformInfo.subPlan?.name !== 'Trial' || paymentState !== EPaymentRequiredType.NON}
                        onClick={() => setShowSettings2(true)}>
                  External storage
                </Button>
              </CustomTooltip>
            )}
            {!!repos?.length && ((userCanBackup || isTestUser) ? (
              <CustomTooltip
                title={(platformInfo.subPlan?.name !== 'Pro' && platformInfo.subPlan?.name !== 'Trial') ? 'This feature is only available with a Pro Plan subscription' : ''}>
                <Button
                  ml={2} variant="outlined"
                  color="primary"
                  onClick={() => (platformInfo.subPlan?.name === 'Pro' || platformInfo.subPlan?.name === 'Trial') ? backupAllNow() : updateSubscriptionPlanModal({
                    translate,
                    platformName,
                    navigate
                  })}
                  disabled={(platformInfo.subPlan.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED) ||
                    (platformInfo.subPlan.type === ESubscriptionPlans.TRIAL && trialState === ETrialStatus.EXPIRED) ||
                    paymentState !== EPaymentRequiredType.NON}
                >
                  <DatabaseIcoInvert className="feather-icon-in-button"/>
                  {translate('views.platform_pages.backupNow')}
                </Button>
              </CustomTooltip>
            ) : (
              <CustomTimerTooltip setUserCanBackup={() => setUserCanBackup(true)}>
                <Button
                  ml={2} variant="outlined"
                  color="primary" onClick={backupAllNow}
                  disabled={true}
                >
                  <DatabaseIcoInvert className="feather-icon-in-button"/>
                  {translate('views.platform_pages.backupNow')}
                </Button>
              </CustomTimerTooltip>
            ))}
            {!!platformInfo.users?.length && (
              <Button ml={2} variant="contained" color="primary" onClick={addRepos}>
                <Plus className="feather-icon-in-button"/>
                {translate(`forms.gitlab_add_edit_project.${isSnip ? 'add_snip_num' : 'add_project_num'}`, {num: ''})}
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="header-block header-block-sub">
        <div className="user-name">
          {!!users?.length && (
            <Select
              value={isSnip ? 'snip' : 'proj'}
              className='github-org-select'
              MenuProps={{id: 'sub-github-select'}}
            >
              <MenuItem
                key='0-gist'
                value='proj'
                onClick={() => {
                  setIsLoading(true)
                  restartBadge()
                  clearState()
                  dispatch(fetchItemsForGitlabUser({
                    page: page,
                    perPage: 10
                  })).unwrap().finally(() => setIsLoading(false))
                  setIsSnip(false)
                  localStorage['gitlabType'] = 'project'
                }}
              >
                @{users[0].username}: Projects
              </MenuItem>
              <MenuItem
                key='1-gist'
                value='snip'
                onClick={() => {
                  setIsLoading(true)
                  restartBadge()
                  clearState()
                  setSearchTerm('')
                  dispatch(fetchSnippetsForGitlabUser({
                    page: page,
                    perPage: 10
                  })).unwrap().finally(() => setIsLoading(false))
                  setIsSnip(true)
                  localStorage['gitlabType'] = 'snippet'
                }}
              >
                @{users[0].username}: Snippets
              </MenuItem>
            </Select>
          )}
        </div>
        <div className="search-block">
          {(!!repos?.length || !!searchTerm ) && (
            <SearchInput
              className="search-field"
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={onSearch}
              endAdornment={
                <InputAdornment position="end" className="search-icon">
                  <img src={searchIcon} loading="lazy"/>
                </InputAdornment>
              }
            />
          )}
        </div>
      </div>
      <Box sx={{width: '100%'}}>
        {isLoadingGitlabUsers || isLoadingRepos || userLoading || isLoading ?
          <div className="spinner-wrapper">
            <CircularProgress/>
          </div>
          :
          <div className="data-block">
            {validationBlock()}
            {!validationBlock() &&
                <>
                    <div className="repo-wrapper">
                        <div className="repos-list">
                            <PlatformItemsList
                                items={platformInfo.filteredItems || []}
                                updItems={() => dispatch(isSnip ? fetchSnippetsForGitlabUser({
                                  page: page,
                                  perPage: 10
                                }) : fetchItemsForGitlabUser({page: page, perPage: 10})).unwrap()}
                                platformName={platformName}
                                subPlatform={isSnip ? 'snippet' : ''}
                                onDelete={handleDeleteRepo}
                                onBulkDelete={handleDeleteBulkBoard}
                              // onBackup={(id) => dispatch(backupGitlabItem(id)).unwrap()}
                                setSelectAll={(isSelect) => {
                                  if (!isSelect) {
                                    setSelectList([])
                                    setSelectAll(isSelect)
                                  } else {
                                    const listToSelect = platformInfo.filteredItems?.filter(item => !item.actionProcess && item.status !== "initialized").map(item => item.id) || []
                                    setSelectList(listToSelect)
                                    if (listToSelect.length === platformInfo.filteredItems?.length) {
                                      setSelectAll(isSelect)
                                    }
                                  }
                                }}
                                selectAll={selectAll}
                                setSelectList={(name) => (
                                  setSelectList(prevState => {
                                    const indexToRemove = prevState.indexOf(name);
                                    if (indexToRemove !== -1) {
                                      prevState.splice(indexToRemove, 1);
                                    } else {
                                      prevState.push(name)
                                    }
                                    if (!!prevState?.length && !!platformInfo.filteredItems?.length &&
                                      ((prevState.length === platformInfo.filteredItems.length && !selectAll) ||
                                        (prevState.length !== platformInfo.filteredItems.length && selectAll))) {
                                      setSelectAll(prevState.length === platformInfo.filteredItems.length)
                                    }
                                    if (!prevState.length) {
                                      setSelectAll(false)
                                    }
                                    return [...prevState]
                                  })
                                )}
                                selectList={selectList}
                                subPlan={platformInfo.subPlan}
                                trialState={trialState}
                                total={total}
                                page={page}
                                setPage={(p) => {
                                  setPage(p)
                                  clearState(true)
                                  fetchData(p, searchTerm)
                                }}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </>
            }
            <GitlabRepoAdd
              availableItemsLeft={getRemainingItems()}
              updItems={() => {
                dispatch(isSnip ? fetchSnippetsForGitlabUser({
                  page: page,
                  perPage: 10
                }) : fetchItemsForGitlabUser({page: page, perPage: 10})).unwrap()
                restartBadge()
              }}
              isOpen={notification.isOpen}
              user={users ? users[0] : {}}
              closeNotification={closeNotification}
              platformName={platformName}
              paymentType={paymentState}
              subPlan={platformInfo.subPlan}
              isSnip={isSnip}
            />
            <PaymentRequiredPoppup
              isOpen={paymentPoppup.isOpen}
              closeNotification={closeTrialPoppup}
              type={paymentState}
              platformName={paymentPoppup.platformName}
            />
            <Modal
              isOpen={showSettings}
              closeNotification={() => setShowSettings(false)}
              title='Edit settings'
              child={<GitlabUserAddEdit
                user={users ? users[0] : {}}
                subPlan={platformInfo.subPlan}
              />}
            />
            <Modal
              isOpen={showSettings2}
              className='ext-storage-modal'
              closeNotification={() => setShowSettings2(false)}
              child={<ExternalStorage
                platformModal='gitlab'
                closeModal={() => setShowSettings2(false)}
              />}
            />
            <DeleteItemDialog
              isOpen={!!deleteDate?.name?.length || !!deleteBulkDate.length}
              closeNotification={() => {
                clearState(true)
              }}
              isBulk={!!deleteBulkDate.length}
              platformName={platformName}
              bulkData={deleteBulkDate}
              singleData={deleteDate}
              paymentState={paymentState}
              items={platformInfo.filteredItems || []}
              name={deleteDate?.name || ''}
              updItems={() => dispatch(isSnip ? fetchSnippetsForGitlabUser({
                page: page,
                perPage: 10
              }) : fetchItemsForGitlabUser({page: page, perPage: 10})).unwrap()}
              onConfirm={() => {
                deleteBulkDate.length > 1 ?
                  onConfirmDeleteRepo(deleteBulkDate, true) :
                  onConfirmDeleteRepo(deleteBulkDate[0])
              }}
            />
          </div>
        }
      </Box>
    </div>
  );
};

export default withRedirectAfterLogout()(GitlabPlatformSettings);
