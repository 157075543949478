import { createAction, createSlice } from '@reduxjs/toolkit';
import { ECardTypes } from '../../models/consts';
import { IStripeCard, IUser } from '../../models/models';
import {
  editUser,
  getStripeDefaultPaymentMethod,
  getUser,
  getUsersPaymentsHistory,
  logout, setUserLoading,
  setStripeDefaultPaymentMethod, unsubscribeMailProfile,
  updateUserAvatar,
  updateUserData, updBillingAddress, setUserTestData, setUserPro
} from '../actions';
import { EUser } from '../constants';
import {IBillingAddressUser} from "../../models/inner-models";
import UserTestInfo from "../../models/userTest";

export interface UserState {
  loading: boolean;
  user: IUser | null;
  stripePayment?: IStripeCard | null;
  underWork: boolean;
  isHasProPlan: boolean;
}

const initialState: UserState = {
  loading: true,
  user: null,
  stripePayment: null,
  underWork: false,
  isHasProPlan: false
};

const clearUserState = createAction(EUser.resetUser);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { actions, reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (state, { payload }) => {
        if (payload) {
          if (!payload.hadEverAnySubscriptions) {
            localStorage['firstTime'] = 'true'
          }
          const user: IUser = {
            ...payload,
            company: payload.company?.card
          }
          state.underWork = false;
          state.user = user as IUser;
        }
        state.loading = false;
      })
      .addCase(setUserLoading.fulfilled, (state, {payload}) => {
        state.loading = !!payload;
      })
      .addCase(setUserPro.fulfilled, (state, {payload}) => {
        state.isHasProPlan = payload;
      })
      .addCase(setUserTestData.fulfilled, (state, {payload}) => {
        state.loading = payload;
        state.underWork = true;
        state.user = UserTestInfo as IUser;
      })
      .addCase(updBillingAddress.fulfilled, (state, { payload }) => {
        if (!!payload?.length && state.user) {
          const user: IUser = { ...state.user };
          user.company = payload[0] as IBillingAddressUser;
          state.user = user;
        }
        state.loading = false;
      })
      .addCase(logout.fulfilled, (state, { payload }) => {
        if (payload) {
          state.user = null;
        }
      })
      .addCase(editUser.fulfilled, (state, { payload }) => {
        if (payload && state.user) {
          state.user = payload as IUser;
        }
      })
      .addCase(updateUserAvatar.fulfilled, (state, { payload }) => {
        if (payload && state.user) {
          const user: IUser = { ...state.user };
          user.picture = payload as string;
          state.user = user;
        }
      })
      .addCase(unsubscribeMailProfile.fulfilled, (state, { payload }) => {
        if (payload && state.user) {
          const user: IUser = { ...state.user };
          user.isSubscribedToMail = payload.isSubscribedToMail as boolean;
          state.user = user;
        }
      })
      .addCase(updateUserData.fulfilled, (state, { payload }) => {
        if (payload && state.user) {
          const user: IUser = { ...state.user };
          user.name = payload.name as string;
          user.family_name = payload.family_name as string;
          state.user = user;
        }
      })
      .addCase(getUsersPaymentsHistory.fulfilled, (state, { payload }) => {
        if (payload && state.user) {
          state.user.paymentHistory = payload.payments?.map((item, idx) => ({ id: idx, ...item }));
        }
      })
      .addCase(getStripeDefaultPaymentMethod.fulfilled, (state, { payload }) => {
        if (payload && state.user) {
          state.stripePayment = {
            cardType: payload.card,
            lastDigits: payload.last_4,
          };
        } else if (state.user) {
          state.stripePayment = null;
        }
      })
      .addCase(getStripeDefaultPaymentMethod.rejected, (state) => {
        if (state.user) {
          state.stripePayment = null;
        }
      })
      .addCase(setStripeDefaultPaymentMethod.fulfilled, (state, { payload, meta }) => {
        if (payload && state.user) {
          state.stripePayment = {
            cardType: meta.arg.card as ECardTypes,
            lastDigits: meta.arg.last_4,
          };
        }
      })
      .addCase(clearUserState, () => initialState);
  },
});

export { clearUserState };

export default reducer;
