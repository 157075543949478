import { CircularProgress } from '@mui/material';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import {errorBigIcon, masterCard, visa, toastSuccess, pen} from '../../../../assets/icons/icons';
import { Button } from '../../../../components/StyledComponents';
import { ECardTypes } from '../../../../models/consts';
import { IError } from '../../../../models/inner-models';
import { getStripeDefaultPaymentMethod, setStripeDefaultPaymentMethod } from '../../../../store/actions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { isAuthorized, stripePaymentMethod } from '../../../../store/selectors';
import '../style.scss';

const useOptions = () => {
  return {
    style: {
      base: {
        color: '#000000',
        fontSize: '13px',
        fontWeight: 300,
        opacity: 0.7,

        '::placeholder': {
          color: '#D1D5DB',
          fontSize: '13px',
          fontWeight: 300,
          opacity: 0.7,
        },
      },
      invalid: {
        color: '#FC5640',
      },
    },
  };
};


const ChangePasswordForm: FunctionComponent<{buySub?: ()=>void, closeModal?: ()=>void, isSubP?: boolean, isSubBtn?: (t:boolean)=>void}> = ({buySub, closeModal, isSubP, isSubBtn}) => {
  const stripe = useStripe();
  const { t: translate } = useTranslation();
  const [isCardUpdating, setIsCardUpdating] = useState<boolean>(false);
  const options = useOptions();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const defaultPaymentMethod = useAppSelector(stripePaymentMethod);
  const isAuthed = useAppSelector(isAuthorized);
  const [addCard, setAddCard] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getStripeDefaultPaymentMethod()).unwrap();
      } catch (e) {
        console.log(e);
      }
    };

    if (!isAuthed) {
      return;
    }

    fetchData();
  }, []);

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);

      if (!cardElement) {
        return;
      }
      setIsCardUpdating(true);

      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (!paymentMethod) {
        setIsCardUpdating(false);

        await Swal.fire({
          imageUrl: errorBigIcon,
          title: translate('notifications.titles.error'),
          text: translate('notifications.payment.incorrect_card'),
        });
        return;
      }

      await dispatch(setStripeDefaultPaymentMethod({
        id: paymentMethod?.id as string,
        card: paymentMethod?.card?.brand as string,
        last_4: Number(paymentMethod?.card?.last4),
      })).unwrap();
      setIsCardUpdating(false);
      if (closeModal) {
        closeModal()
      }
      setAddCard(false)
      if (isSubP) {
        if (isSubBtn) {
          isSubBtn(false)
        }
      }

      if (!buySub) {
        await Swal.fire({
          toast: true,
          position: 'top-end',
          timerProgressBar: true,
          showConfirmButton:false,
          showCloseButton: true,
          imageUrl: toastSuccess,
          timer: 3000,
          title: translate('notifications.titles.success'),
          text: translate('notifications.payment.success_card_change'),
        });
      }
      if (buySub) {
        buySub()
      }
    } catch (err) {
      const error = err as IError;
      setIsCardUpdating(false);

      Swal.fire({
        imageUrl: errorBigIcon,
        title: translate('notifications.titles.error'),
        text: error.error,
      });
    }
  };

  return (
    <div className="card-user-block">
      <div className="block-label">
        <div className="block-label-title">Payment Method</div>
        <div className="block-label-desc">How do you want to pay for your BackupLABS subscriptions?</div>
      </div>
      {defaultPaymentMethod &&  (
        <div className="block-label">
          <div className='pref-card-title'>
            {translate('forms.common.payment_method')}
          </div>
          <div className="payment-method">
            <div className="card-element">
              <img className="card-icon"
                   src={defaultPaymentMethod?.cardType === ECardTypes.MASTERCARD ? masterCard : visa} loading="lazy"
              />
              **** **** **** {defaultPaymentMethod?.lastDigits }
            </div>
          </div>
          <small>{translate('forms.common.payment_method_pref_desc_2')}</small>
        </div>
      )}

      {(!defaultPaymentMethod || addCard) && (
        <form onSubmit={handleSubmit}>
          <label className="label-block">
            {translate('notifications.payment.card_number')}
            <CardNumberElement options={options} className="card-elem" />
          </label>
          <label className="label-block">
            {translate('notifications.payment.expire')}
            <CardExpiryElement options={options} className="card-elem " />
          </label>
          <label className="label-block">
            {translate('notifications.payment.cvc')}
            <CardCvcElement options={options} className="card-elem" />
          </label>
          <Button
            variant="outlined" color="primary"
            className='newapp-back'
            onClick={()=> {
              setAddCard(false)
              if (isSubBtn) {
                isSubBtn(false)
              }
            }}
            mr={4}
          >
            Cancel
          </Button>
          <Button
            disabled={!stripe || isCardUpdating}
            variant="contained"
            color="primary"
            type="submit"
          >
            {isCardUpdating &&
              <div className="small-spinner-wrapper">
                <CircularProgress color="inherit" style={{ width: '20px', height: '20px', marginTop: '6px' }} />
              </div>}
            {translate('notifications.choices.saveCard')}
          </Button>
        </form>
      )}
      {defaultPaymentMethod && !addCard && (
          <Button
            variant="contained" color="secondary"
            className='newapp-back'
            onClick={()=> {
              setAddCard(true)
              if (isSubBtn) {
                isSubBtn(true)
              }
            }}
            mb={4}
          >
            <img src={pen} className="edit-icon" style={{marginRight: '4px'}} loading="lazy"/>
            {isSubP ? 'Change Card' : 'Change Card'}
          </Button>
      )}
    </div>
  );
};

export default ChangePasswordForm;
