import { ISubscriptionMockData } from './inner-models';
import { EGithubPlanNames } from './paymentPlans/github-payments-plan';

export const DRAWER_WIDTH = 240;

export enum EWindowMaxWidth {
  TABLET = 1280,
  MOBILE = 767
}

export enum ENotificationTypes {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  WARNING_EMAIL = 'warning_email',
  CONFIRM = 'confirm',
  NONE = 'none'
}

export const EMAIL_REGEX = /^[\w-\\.]+@([\w-]+\.)+[\w-]+$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*^"])[a-zA-Z0-9@$!%*^"]{10,}$/;
export const OLD_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*^"])[a-zA-Z0-9@$!%*^"]{8,}$/;

export const validateEmails = (value: string) => {
  if (!value.trim()) {
    return true;
  }

  const emailsArray = value.split(',').map((email) => email.trim());

  if(!emailsArray[emailsArray.length-1].length){
    return 'Please, write email after comma'
  }

  const isEveryEmailValid = emailsArray.every(email => EMAIL_REGEX.test(email));

  return isEveryEmailValid || 'Invalid email format';
};

export enum ELocalStoreKeys {
  TOKEN = 'idToken',
  IS_GOOGLE = 'isGoogle'
}

export enum EErrorText {
  UNAUTHORIZED = 'The user is not authenticated',
  NOT_CONFIRMED = 'User is not confirmed',
  INVALID_LAMBDA = 'Invalid lambda function output',
  EXPIRED_TOKEN = 'The incoming token has expired',
  SUB_PLAN_ALREADY_EXIST = 'You trying to subscribe to already active plan'
}

export enum EPlatformsNames {
  GITHUB = 'github',
  GITLAB = 'gitlab',
  JIRA = 'jira',
  TRELLO = 'trello',
  NOTION = 'notion',
  // BITBUCKET = 'bitbucket',
  // WRIKE = 'wrike',
  ASANA = 'asana',
  // AIRTABLE = 'airtable',
  // CLICKUP = 'clickup'
}

export const EActivePlatformsNames = ['GitHub','GitLab','Trello','Jira','Notion']

export enum ESubscriptionPlans {
  NO_SUB = 0,
  TRIAL = 1,
  SUBSCRIPTION = 2,
}

export enum EActionStatus {
  SUCCESS = 'success',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
  DELETED = 'deleted',
  INITIALIZED = 'initialized',
}

export enum ERepoBoardStatus {
  BACKUPED = 'backuped',
  DELETED = 'deleted',
  INITIALIZED = 'initialized'
}

export enum JiraProjectsType  {
  company_managed_kanban = ' / CM',
  company_managed_scrum = ' / CM',
  bug_tracking = ' > BUGT',
  team_managed_scrum = ' / TM',
  team_managed_kanban = ' / TM'
}

export enum JiraProjectsTypeKeyTemplates  {
  service_desk = ' JSM /',
  software = 'JS/C /',
  business = 'JWM /',
  product_discovery = 'JPD /',
}

export enum JiraProjectsTypeKey  {
  service_desk = 'service_desk',
  software = 'software',
  business = 'business',
  product_discovery = 'product_discovery',
}
export enum EGithubUserTypes {
  ORGANIZATION = 'Organization',
  USER = 'User',
}

export const EPlatformPrices = {
  github: process.env.REACT_APP_STRIPE_GITHUB_PRODUCT_KEY,
  trello: process.env.REACT_APP_STRIPE_TRELLO_PRODUCT_KEY,
  gitlab: process.env.REACT_APP_STRIPE_GITLAB_PRODUCT_KEY,
  jira: process.env.REACT_APP_STRIPE_JIRA_PRODUCT_KEY,
  notion: process.env.REACT_APP_STRIPE_NOTION_PRODUCT_KEY,
};

export const EProPlatformPrices = {
  github: process.env.PRO_STRIPE_GITHUB_PRODUCT_KEY,
  trello: process.env.PRO_STRIPE_TRELLO_PRODUCT_KEY,
  gitlab: process.env.PRO_STRIPE_GITLAB_PRODUCT_KEY,
  jira: process.env.PRO_STRIPE_JIRA_PRODUCT_KEY,
  notion: process.env.PRO_STRIPE_NOTION_PRODUCT_KEY,
};

export enum EPrivacy {
  PRIVACY = 'privacy-policy',
  TERMS = 'terms-of-use'
}

export enum ETrialStatus {
  NOT_STARTED = 'not started',
  STARTED = 'active',
  EXPIRED = 'expired'
}

export enum ECardTypes {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
}

export enum EStripeSubStatus {
  ACTIVE = 'active',
  PAST = 'past_due',
  UNPAID = 'unpaid',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
  EXPIRED = 'incomplete_expired',
  TRIAL = 'trialing',
  INACTIVE = 'inactive'
}

export const activeSubscriptionStatuses = [
  EStripeSubStatus.ACTIVE,
  EStripeSubStatus.PAST,
  EStripeSubStatus.UNPAID,
];

export const inactiveSubscriptionStatuses = [
  EStripeSubStatus.INACTIVE,
];

export const errorSubscriptionStatuses = [
  EStripeSubStatus.CANCELED,
  EStripeSubStatus.EXPIRED,
];

export const unpaidSubscriptionStatuses = [
  EStripeSubStatus.UNPAID,
  EStripeSubStatus.INCOMPLETE,
  EStripeSubStatus.PAST,
  EStripeSubStatus.TRIAL,
];

export const SubscriptionMockData: ISubscriptionMockData[] = [
  {
    type: ESubscriptionPlans.NO_SUB,
  },
  {
    type: ESubscriptionPlans.TRIAL,
    name: 'Trial',
    periodHours: 4,
  },
  {
    type: ESubscriptionPlans.SUBSCRIPTION,
    name: 'Essential',
    cost: '$24/m',
    isPaid: true,
  },
];

export enum EPaymentRequiredType {
  NON = 'non',
  TRIAL = 'trial',
  SUB = 'subscription'
}

export const marks = [
  {
    value: 10,
    label: '',
  },
  {
    value: 50,
    label: '',
  },
  {
    value: 100,
    label: '',
  },
  {
    value: 250,
    label: '',
  },
  {
    value: 500,
    label: '',
  },
  {
    value: 1000,
    label: '',
  },
  {
    value: 1500,
    label: '',
  },
];

export const MAX_AVATAR_IMAGE_SIZE = 1000000; // 1 Mb
export const MAX_INPUT_VALUE_LENGTH_AVAILABLE = 1000; // to prevent crashes due to large text paste in input
export const MAX_INPUT_VALUE_LENGTH = 50;
export const MAX_INPUT_VALUE_EMAIL_LENGTH = 250;
export const MIN_INPUT_VALUE_LENGTH = 2;

export type EPlatformPlanName = EGithubPlanNames;
export const PING_INTERVAL = 10000;
export const PING_COUNT = 20;

export const minRepositoriesAmount = 10;
export const minSubscriptionPricePerMonth = 12;

export const minBoardsAmount = 5;
export const minTrelloSubscriptionPricePerMonth = 5;
export const minJiraSubscriptionPricePerMonth = 5;
