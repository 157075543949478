import React from 'react';
import { matchPath } from 'react-router-dom';
import SidebarNavList from './SidebarNavList';

import SidebarNavListItem from './SidebarNavListItem';
import { ReduceChildRoutesProps } from './types';
import {ETrialStatus} from "../../models/consts";

const reduceChildRoutes = ({ items, page, depth, currentRoute, bigMenu, onClose, platform, hasProPlan }: ReduceChildRoutesProps) => {

  if (platform && !!Object.keys(platform).length) {
    const platformsName = Object.keys(platform)
    const curPlatform = platformsName.find(platName => page.href.indexOf(platName) !== -1) || ''

    if ((hasProPlan && page.href === '/audit') || page.href !== '/audit') {
      if (page.children) {
        const open = page.href
          ? !!matchPath(
            {
              path: page.href,
              end: false,
            },
            currentRoute,
          )
          : false;

        items.push(
          <SidebarNavListItem
            bigMenu={bigMenu}
            depth={depth}
            icon={page.icon}
            key={page.title}
            badge={page.badge}
            open={!!open}
            title={page.title}
            href={page.href}
            onClose={onClose}
          >
            <SidebarNavList bigMenu={bigMenu} depth={depth + 1} pages={page.children} onClose={onClose} />
          </SidebarNavListItem>,
        );
      } else if ((platform[curPlatform]?.trial?.trialStatus && platform[curPlatform]?.trial?.trialStatus !== ETrialStatus.NOT_STARTED) || !curPlatform?.length) {
        items.push(
          <SidebarNavListItem
            bigMenu={bigMenu}
            depth={depth}
            href={page.href}
            icon={page.icon}
            key={page.title}
            badge={page.badge}
            title={page.title}
            onClose={onClose}
          />,
        );
      }
    }
  }

  return items;
};

export default reduceChildRoutes;
