import {Checkbox, Chip as MuiChip, CircularProgress, Pagination, Stack, Typography} from '@mui/material';
import {DataGrid, GridCellParams, GridHeader} from '@mui/x-data-grid';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {Info, MessageSquare} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {CheckboxInputChecked, CheckboxInputEmpty, errorBigIcon, toastSuccess} from '../../../assets/icons/icons';
import {EPlatformsNames, ERepoBoardStatus, ESubscriptionPlans, ETrialStatus} from '../../../models/consts';
import {IItem, IPlatformUser, SubPlanInfo} from '../../../models/models';
import Platform from '../../../models/platforms-data';
import {CustomTooltip} from '../../../styles/components/CustomTooltip';
import {Button} from '../../StyledComponents';
import {IItemRow} from './models';

import './style.scss';
import {IDailyStatusBody, IError} from "../../../models/inner-models";
import Swal from "sweetalert2";
import {decoratePopUpMessage} from "../../../utils/popUpTextDecorator";
import styled from 'styled-components';
import {CustomToggle} from "../../../styles/components/CustomToggle";
import {useAppDispatch} from "../../../store/hooks";
import {setStatusDailyBackup} from "../../../store/actions";
import {useWebSocket} from "../../../contexts/WebSocketContext";
import {updatePlatformState} from "../../../store/reducers/platform";
import {updateSubscriptionPlanModal} from "../../../utils/functions/updateSubscriptionPlanModal";
import {EWebsocketMethods} from "../../../constants";

const Chip = styled(MuiChip)<{ color: string }>`
  height: 22px;
  box-sizing: border-box;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 16px;
  text-transform: capitalize;

  span {
    height: 20px;
  }

  &.success {
    background-color: #D1FAE5;
    color: #065F46;
  }

  &.error {
    background-color: #FEE2E2;
    color: #991B1B;
  }

  &.process {
    background-color: #FEF3C7;
    color: #92400E;

    & span {
      padding-right: 8px;
    }

    &::after {
      content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="rotate-ico"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg>');
      width: 14px;
      height: 14px;
      margin-right: 8px;
      margin-bottom: 1px;
      animation: back-rotation 1.5s infinite linear;
    }
  }
`;

const s = {
  hideHeader: {
    '.MuiDataGrid-columnHeaders': {
      display: 'none'
    },
    '.MuiDataGrid-virtualScroller	': {
      marginTop: '0!important'
    }
  }
}

interface IBackupItems {
  id: string;
  name: string;
  isRepo?: boolean;
  projectTypeKey?: string;
}

interface IPlatformListProps {
  items: Array<IItem>;
  platformName: EPlatformsNames;
  subPlatform?: string;
  onDelete?: (id: string, name: string, type?: string) => void;
  onBulkDelete?: (arr: string[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // onBackup: (id: string, name: string, type?: boolean) => Record<string, any>;
  subPlan: SubPlanInfo;
  setSelectAll: (boolean) => void;
  selectAll: boolean;
  setSelectList: (string) => void;
  updItems: () => void;
  selectList: Array<string>;
  trialState?: string;
  appWasDeleted?: boolean;
  users?: IPlatformUser[];
  total: number;
  page: number;
  setPage: (number) => void;
  openTutorial?: () => void;
  isLoading: boolean;
}

const PlatformItemsList: FunctionComponent<IPlatformListProps> = ({
                                                                    items,
                                                                    updItems,
                                                                    platformName,
                                                                    subPlatform,
                                                                    // onDelete,
                                                                    onBulkDelete,
                                                                    // onBackup,
                                                                    subPlan,
                                                                    selectAll,
                                                                    selectList,
                                                                    setSelectAll,
                                                                    setSelectList,
                                                                    trialState,
                                                                    appWasDeleted,
                                                                    users,
                                                                    page,
                                                                    setPage,
                                                                    total,
                                                                    isLoading,
                                                                    openTutorial,
                                                                  }: IPlatformListProps) => {
    const {t: translate} = useTranslation();
    const [rows, setRows] = useState<Array<IItemRow>>([]);
    const [cacheRows, setCacheRows] = useState<Array<IItemRow>>([]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Icon = Platform[platformName]?.miniatureLogo as React.FC<any>;
    const [updating, setIsUpdating] = useState<boolean>(true);
    const [updatingDaily, setIsUpdatingDaily] = useState<boolean>(false);
    const pageSize = 10;
    // const count = Math.ceil(rows.length / pageSize)
    const hasSelectedListActiveItem = selectList?.some(item => rows.find(rowItem => (rowItem.itemId === item))?.isActive)
    const hasSelectedListNoProcessItem = selectList?.some(item => !rows.find(rowItem => (rowItem.itemId === item))?.actionProcess)
    const hasSelectedActiveListNoProcessItem = selectList?.some(item => {
      const currentRow = rows.find(rowItem => (rowItem.itemId === item))
      return !currentRow?.actionProcess && currentRow?.isActive
    })
    const {onMessage} = useWebSocket();

    // useEffect(()=>{
    //   if (!!rows.length && count < page || !rows.length && count){
    //     setPage(1)
    //   }
    // }, [count])

    useEffect(() => {
      setIsUpdating(true);
      setRows(items.map(({
                           id,
                           size,
                           lastBackup,
                           isDailyActive,
                           actionProcess,
                           projectTypeKey,
                           board,
                           repo,
                           project,
                           workspace,
                           isActive,
                           status,
                           isRepo,
                           description,
                           typeManage
                         }, idx) => ({
        name: repo || project || board || workspace || '-',
        platformName: platformName,
        isActive: isActive,
        isDailyActive: isDailyActive,
        actionProcess: actionProcess,
        id: idx,
        itemId: id,
        lastBackup: lastBackup,
        projectTypeKey: projectTypeKey,
        size: size,
        status: status,
        isRepo: !!isRepo || !subPlatform,
        description: description || '',
        typeManage: typeManage || ''
      } as IItemRow)));
      setIsUpdating(false);
    }, [platformName, items, subPlan]);

    useEffect(() => {
      setCacheRows(prevState => {
        rows.forEach(item => {
          if (prevState.findIndex(i => i.itemId === item.itemId) === -1) {
            prevState.push(item)
          }
        })
        return [...prevState]
      })
    }, [rows])

    useEffect(() => {

      onMessage((data) => {
          if(![EWebsocketMethods.itemsAddedCountChange,EWebsocketMethods.updateDashboardPlatformItemsAmount].includes(data.method)){
            dispatch(updatePlatformState(data))
          }
      });

    }, []);

    const redirectToBackup = (run: boolean, id: string, name: string, type?: boolean, projectTypeKey?: string, status?:string) => {
      navigate(`/platform/backups/${platformName.toLowerCase()}?name=${encodeURIComponent(name?.split('::')[0])}&id=${id}${(platformName === 'github' || platformName === 'gitlab') && !type ? '&type=sub' : ''}${!!users?.length ? ('&user=' + users[0].username) : ''}${projectTypeKey ? '&projectTypeKey=' + projectTypeKey : ''}${[EPlatformsNames.TRELLO,EPlatformsNames.GITHUB].includes(platformName) && status ? '&status=' + status : ''}${run ? '&run=true' : ''}`);
    };

    // const backupNow = async (id: string, name: string, type?: boolean) => {
    //   try {
    //     const backResp = await onBackup(id, name, type);
    //
    //     await Swal.fire({
    //       title: translate('notifications.titles.success'),
    //       text: backResp.message || translate('notifications.github_repos.start_backup'),
    //       toast: true,
    //       position: 'top-end',
    //       timerProgressBar: true,
    //       showConfirmButton:false,
    //       showCloseButton: true,
    //       imageUrl: toastSuccess,
    //       timer: 3000,
    //     });
    //   } catch (err) {
    //     const error = err as IError;
    //
    //     await Swal.fire({
    //       title: translate('notifications.titles.error'),
    //       text: decoratePopUpMessage(error.error as string),
    //       imageUrl: errorBigIcon,
    //       confirmButtonText: translate('notifications.choices.close'),
    //     });
    //   }
    // }

    const setDailyStatus = async (active: boolean, single?) => {
      try {
        const payload: IDailyStatusBody = {is_daily_backed_up: active}
        setIsUpdatingDaily(true)
        selectList = selectList?.filter(item => {
          const row = rows.find(rowItem => (rowItem.itemId === item))
          return row?.isActive && !row?.actionProcess && row?.status !== ERepoBoardStatus.INITIALIZED
        })
        switch (platformName) {
          case 'trello':
            payload.boardsIds = single ? [single.itemId] : selectList
            break;
          case 'github':
            if (subPlatform) {
              payload.gistsIds = single ? [single.itemId] : selectList
            } else {
              payload.reposIds = single ? [single.itemId] : selectList
            }
            break;
          case 'gitlab':
            if (subPlatform) {
              payload.snippetsIds = single ? [single.itemId] : selectList
            } else {
              payload.projectsIds = single ? [single.itemId] : selectList
            }
            break;
          case 'jira':
            payload.projectsIds = single ? [single.itemId] : selectList
            break;
          case 'notion':
            payload.workspacesIds = single ? [single.itemId] : selectList
            break;
          default:
            break;
        }
        await dispatch(setStatusDailyBackup({payload: payload, platform: platformName})).unwrap();

        if (!single) {
          setIsUpdating(true);
        }
        setRows(prevState => {
          const tempArr = prevState.map(prevItem => {
            if ((single && prevItem.itemId === single.itemId) || (!single && selectList.indexOf(prevItem.itemId) !== -1)) {
              return {...prevItem, isDailyActive: active}
            } else {
              return prevItem
            }
          })
          return [...tempArr]
        })
        setSelectAll(false)
        updItems()
        setIsUpdating(false);
        setIsUpdatingDaily(false);

        await Swal.fire({
          title: translate('notifications.titles.success'),
          text: single ?
            active ? translate('notifications.all.activeStatus') : translate('notifications.all.inactiveStatus') :
            active ? translate('notifications.all.activeStatus_many') : translate('notifications.all.inactiveStatus_many'),
          toast: true,
          position: 'top-end',
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true,
          imageUrl: toastSuccess,
          timer: 3000,
        });
      } catch (err) {
        const error = err as IError;

        await Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: errorBigIcon,
          confirmButtonText: translate('notifications.choices.close'),
        });
      }
    }

    const columns = [
      {
        field: 'checkbox',
        width: 92,
        editable: false,
        sortable: false,
        headerClassName: 'items-table--header',
        cellClassName: 'items-table-cell--check',
        renderHeader: () => {
          return (
            rows.length === 1 ?
              <div/> :
              <Checkbox
                color="primary"
                checked={selectAll}
                disabled={!rows?.length}
                onChange={(event) => {
                  setSelectAll(event.target.checked as boolean)
                }}
                icon={<CheckboxInputEmpty/>}
                checkedIcon={<CheckboxInputChecked/>}
              />
          );
        },
        renderCell: (params: GridCellParams) => {
          return (
            <>
              {rows.length !== 1 && (
                <Checkbox
                  color="primary"
                  checked={selectList.indexOf(params.row.itemId) !== -1 || selectAll}
                  onChange={() => {
                    setSelectList(params.row.itemId)
                  }}
                  disabled={params.row.lastBackup === '-' && params.row.actionProcess || params.row.status === ERepoBoardStatus.INITIALIZED}
                  icon={<CheckboxInputEmpty/>}
                  checkedIcon={<CheckboxInputChecked/>}
                />
              )}
              <CustomTooltip
                title={(subPlan?.name !== 'Pro' && subPlan?.name !== 'Trial') ? 'This feature is only available with a Pro Plan subscription' : ''}
              >
                <CustomToggle
                  checked={params.row.isDailyActive}
                  disabled={!params.row.isActive || updatingDaily || appWasDeleted || params.row.status === ERepoBoardStatus.DELETED || params.row.actionProcess || params.row.status === ERepoBoardStatus.INITIALIZED}
                  onClick={(e) => {
                    (subPlan?.name === 'Pro' || subPlan?.name === 'Trial') ?
                      setDailyStatus((e.target as HTMLInputElement).checked, params.row) :
                      updateSubscriptionPlanModal({translate, platformName, navigate})
                  }}
                />
              </CustomTooltip>
            </>
          );
        }
      },
      {
        field: 'name',
        headerName: subPlatform ? (platformName === 'github' ? 'gists' : 'snippets') : translate(`views.bulk_restore.itemName.${platformName}.many`),
        flex: 0.7,
        editable: false,
        sortable: true,
        headerClassName: 'items-table--header',
        renderCell: (params: GridCellParams) => {
          const data = params.value.split('::')
          return (
            <div className="name-block"
                 title={
                   params.row.description ?
                     `${data[0]} - ${params.row.description}` :
                     params.row.typeManage ?
                       `${data[0]} - ${params.row.typeManage}` :
                       data[0]
                 }
            >
              {platformName === 'github' && !params.row.isRepo ?
                <MessageSquare className="icon"/> :
                <Icon className={(platformName === 'jira' || platformName === 'notion') ? "icon icon-jira" : "icon"}/>
              }
              {data[1] && <span className='item-private-type'>{data[1]}</span>}
              {params.row.status === ERepoBoardStatus.DELETED &&
                  <CustomTooltip
                      title={`This was deleted in  ${Platform[platformName]?.title}. Download ${ platformName === 'trello' ?'or Single Restore':platformName !== 'notion' ? 'or Restore' :  'backup'} to rescue the ${subPlatform ? (platformName === 'github' ? 'gist' : 'snippet') : translate(`views.bulk_restore.itemName.${platformName}.one`)}.`}>
                      <Info className='delete-tooltip'/>
                  </CustomTooltip>
              }
              <div>
                <span style={{fontWeight: 500}}>{data[0]}</span> <br/>
                {params.row.description}
              </div>
            </div>
          );
        },
      },
      {
        field: 'lastBackup',
        headerName: 'Last backup',
        minWidth: 165,
        maxWidth: 200,
        flex: 0.08,
        editable: false,
        sortable: true,
        headerClassName: 'items-table--header',
        renderCell: (params: GridCellParams) => {
          return (
            <div className="date-block">
              {params.value}
            </div>
          );
        },
      },
      {
        field: 'size',
        headerName: 'Backup size',
        minWidth: 125,
        maxWidth: 160,
        flex: 0.08,
        editable: false,
        sortable: true,
        headerClassName: 'items-table--header',
        renderCell: (params: GridCellParams) => {
          return (
            <div className="size-block">
              {params.value || '-'}
            </div>
          );
        },
      },
      {
        field: 'isActive',
        headerName: 'Status',
        width: 125,
        editable: false,
        sortable: false,
        headerClassName: 'items-table--header',
        renderCell: (params: GridCellParams) => (
          params.value
            ?
            <Chip
              className={!!params.row.actionProcess?.length || params.row.status === 'initialized' ? 'process' : 'success'}
              label={
                !!params.row.actionProcess?.length ?
                  translate(`notifications.all.process.${params.row.actionProcess}`) : params.row.status === 'initialized' && !params.row.actionProcess?.length ? translate('subscriptions.statuses.initialized') :
                    translate('subscriptions.statuses.active')
              }
            />
            : (
              <CustomTooltip title={`${params.row.status === ERepoBoardStatus.DELETED ? translate('views.platform_pages.backups_disabled_del', {platform: Platform[platformName]?.title}) : translate('views.platform_pages.backups_disabled')} ${params.row.status === ERepoBoardStatus.DELETED && platformName === 'trello' ? 'You can only make a single restore.' : ''}`}>
                <Chip
                  className={'error'}
                  label={translate('subscriptions.statuses.inactive')}
                />
              </CustomTooltip>
            )
        ),
      },
      {
        field: 'id',
        headerName: '',
        width: 270,
        // flex: 1,
        editable: false,
        sortable: false,
        headerClassName: 'items-table--header',
        valueGetter: () => '',
        renderCell: (params: GridCellParams) => (
          <div
            className="action-buttons platforms-btn table-actions"
            style={{visibility: !!selectList.length ? 'hidden' : 'visible'}}
          >
            {onBulkDelete && (
              <Button variant="outlined" color="warning"
                // disabled = {params.row.actionProcess || params.row.status === ERepoBoardStatus.INITIALIZED }
                      onClick={() => onBulkDelete([params.row.itemId])}>
                {translate('common.buttons.delete')}
              </Button>
            )}
            <Button
              variant="outlined" color="primary" disabled={params.row.lastBackup === '-'}
              onClick={() => {
                  redirectToBackup(false, params.row.itemId, params.row.name, params.row.isRepo, params.row.projectTypeKey,params.row.status)
              }}
            >
              {translate('common.buttons.view')}
            </Button>
            {platformName !== 'notion' && (
              <Button variant="contained" color="secondary"
                      disabled={platformName === 'notion' || params.row.lastBackup === '-' || updatingDaily || appWasDeleted || params.row.actionProcess || params.row.status === ERepoBoardStatus.INITIALIZED}
                      onClick={() => {
                        redirectToBackup(true, params.row.itemId, params.row.name, params.row.isRepo, params.row.projectTypeKey,params.row.status)
                      }}
              >
                {translate('common.buttons.restore')}
              </Button>
            )}
          </div>
        ),
      },
    ];

    const PaginationFooter = () => {
      const itemFrom = (pageSize * (page + 1)) - pageSize + 1
      const itemTo = (pageSize * (page + 1)) <= total ? (pageSize * (page + 1)) : total
      return (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height='64px'
          px='16px'
        >
          <Typography>{`Showing ${itemFrom} to ${itemTo} of ${total}`}</Typography>
          <Pagination
            count={Math.ceil(total / pageSize)}
            page={page + 1}
            onChange={(e, v) => {
              setPage(v - 1)
            }}
          />
        </Stack>

      )
    };

    const CustomHeader = () => {
      return (
        <div className='custom-header'>
          <div className='custom-header-left-side'>
            <Checkbox
              color="primary"
              checked={selectAll}
              onChange={(event) => {
                setSelectAll(event.target.checked as boolean)
              }}
              icon={<CheckboxInputEmpty/>}
              checkedIcon={<CheckboxInputChecked/>}
              disabled={!rows?.length}
            />
            <div className='left-side-label'>
              {translate('common.buttons.selectAll')} ({selectList?.length})
            </div>
          </div>
          <div className="action-buttons platforms-btn">
            <CustomTooltip
              title={(subPlan?.name !== 'Pro' && subPlan?.name !== 'Trial') ? 'This feature is only available with a Pro Plan subscription' : ''}>
              <Button variant="outlined" color="primary"
                      onClick={() => (
                        (subPlan?.name !== 'Pro' && subPlan?.name !== 'Trial') ?
                          updateSubscriptionPlanModal({
                            translate,
                            platformName,
                            navigate
                          }) :
                          setDailyStatus(false)
                      )}
                      disabled={(!subPlan?.isPaid) || (subPlan.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED) ||
                        (subPlan.type === ESubscriptionPlans.TRIAL && trialState === ETrialStatus.EXPIRED) || appWasDeleted || !hasSelectedListActiveItem || !hasSelectedActiveListNoProcessItem}
              >
                {translate('common.buttons.deactivate')}
              </Button>
            </CustomTooltip>
            <CustomTooltip
              title={(subPlan?.name !== 'Pro' && subPlan?.name !== 'Trial') ? 'This feature is only available with a Pro Plan subscription' : ''}>
              <Button variant="outlined" color="primary"
                      onClick={() => (
                        (subPlan?.name !== 'Pro' && subPlan?.name !== 'Trial') ?
                          updateSubscriptionPlanModal({
                            translate,
                            platformName,
                            navigate
                          }) :
                          setDailyStatus(true)
                      )}
                      disabled={(!subPlan?.isPaid) || (subPlan.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED) ||
                        (subPlan.type === ESubscriptionPlans.TRIAL && trialState === ETrialStatus.EXPIRED) || appWasDeleted || !hasSelectedListActiveItem || !hasSelectedActiveListNoProcessItem}
              >
                {translate('common.buttons.activate')}
              </Button>
            </CustomTooltip>
            {platformName !== 'notion' && (
              <CustomTooltip
                title={selectList.length > 10 ? 'You can restore up to 10 items at a time' : ((subPlan?.name !== 'Pro' && subPlan?.name !== 'Trial') && selectList.length !== 1) ? 'This feature is only available with a Pro Plan subscription' : ''}>
                <Button variant="contained" color="secondary"
                        disabled={(subPlan && !subPlan.isPaid) || (subPlan.type === ESubscriptionPlans.NO_SUB && trialState === ETrialStatus.NOT_STARTED) ||
                          (subPlan.type === ESubscriptionPlans.TRIAL && trialState === ETrialStatus.EXPIRED) ||
                          appWasDeleted || !!selectList?.filter(f => !!rows.find(r => (r.itemId === f && r.lastBackup === '-')))?.length ||
                          !hasSelectedListNoProcessItem || selectList.length > 10}
                        onClick={() => {
                          if ((subPlan?.name !== 'Pro' && subPlan?.name !== 'Trial') && selectList.length !== 1) {
                            return updateSubscriptionPlanModal({translate, platformName, navigate})
                          }

                          if (selectList.length === 1) {
                            const singleItem = cacheRows.find(({itemId}) => itemId === selectList[0])
                            if (singleItem) {
                              redirectToBackup(true, singleItem.itemId, singleItem.name, singleItem.isRepo, singleItem.projectTypeKey, singleItem.status)
                            }
                            return
                          }
                          localStorage['bulkPlatform'] = platformName
                          const tempArr: IBackupItems[] = []
                          selectList.forEach(item => {
                            const result = cacheRows.find(({itemId}) => itemId === item)

                            if (result?.actionProcess || result?.status === 'deleted') {
                              return
                            }

                            tempArr.push({
                              id: item,
                              name: result?.name || '-',
                              isRepo: result?.isRepo || false,
                              projectTypeKey: result?.projectTypeKey
                            })
                          })
                          if (platformName === 'jira') {
                            Swal.fire({
                              title: 'Jira: Restore Confirmation',
                              html: '<div><p><div>1. Create a new project and copy the settings of the original</div><div>2. When you restore, choose a designated project to restore to</div></p> <p><b>Warning!</b> If you do not, it will duplicate your items on the same project</p></div>',
                              showCancelButton: true,
                              showDenyButton: true,
                              denyButtonText: 'Tutorial',
                              cancelButtonText: translate('notifications.choices.cancel'),
                              confirmButtonText: translate('notifications.choices.continue'),
                              imageUrl: errorBigIcon,
                              showLoaderOnConfirm: true,
                            })
                              .then(res => {
                                if (res.isConfirmed) {
                                  localStorage['selectedItem'] = JSON.stringify(tempArr)
                                  setTimeout(() => navigate('/bulk-restore'), 50)
                                } else if (res.isDenied) {
                                  openTutorial && openTutorial()
                                }
                              })
                          } else {
                            localStorage['selectedItem'] = JSON.stringify(tempArr)
                            setTimeout(() => navigate('/bulk-restore'), 50)
                          }
                        }}
                >
                  {translate('common.buttons.restore')}
                </Button>
              </CustomTooltip>
            )}
            <Button variant="outlined" color="warning"
                    disabled={!selectList.length || !hasSelectedListNoProcessItem}
                    onClick={() => {
                      selectList = selectList?.filter(item => {
                        const row = rows.find(rowItem => (rowItem.itemId === item))
                        return !row?.actionProcess
                      })
                      onBulkDelete && onBulkDelete(selectList)
                    }}
            >
              {translate('common.buttons.delete')}
            </Button>
          </div>
        </div>
      );
    }

    return (
      <>
        {updating ?
          <div className="spinner-wrapper">
            <CircularProgress/>
          </div> :
          <DataGrid
            // checkboxSelection
            loading={isLoading}
            rowCount={total}
            paginationMode="server"
            autoHeight
            rows={rows}
            columns={columns}
            headerHeight={48}
            rowHeight={64}
            pageSize={pageSize}
            page={page}
            disableColumnMenu
            disableColumnFilter
            disableDensitySelector
            disableSelectionOnClick
            disableColumnSelector
            initialState={{
              sorting: {
                sortModel: [{field: 'name', sort: 'asc'}],
              },
            }}
            sx={!!selectList?.length || selectAll ? s.hideHeader : {}}
            components={{
              Footer: PaginationFooter,
              Header: !!selectList?.length || selectAll ? CustomHeader : GridHeader
            }}
          />
        }
      </>
    );
  }
;

export default (PlatformItemsList);
