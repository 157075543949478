import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import {
  avatar,
  CheckboxInputChecked,
  CheckboxInputEmpty,
  errorBigIcon,
  toastSuccess
} from '../../../../assets/icons/icons';
import ValidationErrorBlock from '../../../../components/Blocks/ValidationErrorBlock/ValidationErrorBlock';
import { Button, FormControl, TextField } from '../../../../components/StyledComponents';
import { MAX_AVATAR_IMAGE_SIZE, MAX_INPUT_VALUE_LENGTH, MIN_INPUT_VALUE_LENGTH } from '../../../../models/consts';
import { IEditUser, IError, IImageCropperProps } from '../../../../models/inner-models';
import { IUser } from '../../../../models/models';
import {unsubscribeMailProfile, updateUserData} from '../../../../store/actions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getUserSelector } from '../../../../store/selectors';
import { decoratePopUpMessage } from '../../../../utils/popUpTextDecorator';
import ImageCropper from '../ImageCropper/ImageCropper';
import '../style.scss';
import {Checkbox} from "@mui/material";

interface IUserDefault extends IEditUser {
  email: string;
}

interface UserSetProps {
  setLoading: (boolean) => void;
}

const UserInfoForm: FunctionComponent<UserSetProps> = ({setLoading}: UserSetProps) => {
  const user: IUser | null = useAppSelector(getUserSelector);

  const { register, formState: { errors, isValid }, handleSubmit, setValue, trigger } = useForm<IEditUser>();
  const dispatch = useAppDispatch();
  const { t: translate } = useTranslation();
  const [cropper, setCropperNotification] = useState<IImageCropperProps>({
    isOpen: false,
    image: '',
  });
  const [defaultForm, setDefaultForm] = useState<IUserDefault>({
    firstName: '',
    lastName: '',
    email: '',
  });
  const fileInput = useRef<HTMLInputElement>(null);
  const [imageCropped, setCropedImg] = useState<string>();
  const [isSend, setIsSend] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setValue('firstName', user?.name);
      setValue('lastName', user?.family_name);
      trigger(['firstName', 'lastName']);
      setDefaultForm({
        firstName: user.name,
        lastName: user.family_name,
        email: user.email,
      });
      setCropedImg(user?.picture || avatar);
    }
  }, [user?.name, user?.email, user?.family_name, user?.picture]);

  useEffect(() => {
    register('lastName', {
      required: translate('forms.common.required') as string,
      minLength: {
        value: MIN_INPUT_VALUE_LENGTH,
        message: translate('forms.common.min_length'),
      },
      maxLength: {
        value: MAX_INPUT_VALUE_LENGTH,
        message: translate('forms.common.max_length50'),
      },
    });
    register('firstName', {
      required: translate('forms.common.required') as string,
      minLength: {
        value: MIN_INPUT_VALUE_LENGTH,
        message: translate('forms.common.min_length'),
      },
      maxLength: {
        value: MAX_INPUT_VALUE_LENGTH,
        message: translate('forms.common.max_length50'),
      },
    });
  }, [register]);

  const onSubmit: SubmitHandler<IEditUser> = (data) => {
    setIsSend(true)
    dispatch(updateUserData(data))
      .unwrap()
      .then(() => {
        Swal.fire({
          title: translate('notifications.titles.success'),
          text: 'Your changes were successfully saved',
          toast: true,
          position: 'top-end',
          timerProgressBar: true,
          showConfirmButton:false,
          showCloseButton: true,
          imageUrl: toastSuccess,
          timer: 3000,
        });
        setIsSend(false)
      })
      .catch((err) => {
        const error = err as IError;
        setIsSend(false)
        Swal.fire({
          title: translate('notifications.titles.error'),
          text: decoratePopUpMessage(error.error as string),
          imageUrl: errorBigIcon,
          confirmButtonText: translate('notifications.choices.close'),
        });
      });
  };

  const handleChange = (e, field: keyof IEditUser) => {
    setValue(field, e.target.value);
    trigger(field);
    setDefaultForm((prev) => {
      if (prev) {
        const value = { ...prev };
        value[field] = e.target.value;
        return value;
      }
      return prev;
    });
  };

  const onChange = (event) => {
    event.preventDefault();
    const extArr = ['jpeg', 'png', 'jpg', 'bmp'];

    let files;
    let fileUploadPath;
    if (event.dataTransfer) {
      files = event.dataTransfer.files;
      fileUploadPath = event.dataTransfer.value;
    } else if (event.target) {
      files = event.target.files;
      fileUploadPath = event.target.value;
    }

    const ext = fileUploadPath
      .substring(fileUploadPath.lastIndexOf('.') + 1)
      .toLowerCase();

    if (extArr.indexOf(ext) !== -1 && files[0].size <= MAX_AVATAR_IMAGE_SIZE) {
      const reader = new FileReader();
      reader.onload = () => {
        setCropperNotification({
          image: reader.result as string,
          mimeType: files[0].type,
          isOpen: true,
        });
      };
      reader.readAsDataURL(files[0]);
    } else if (extArr.indexOf(ext) === -1) {
      if (event.target) event.target.value = ''
      Swal.fire({
        imageUrl: errorBigIcon,
        title: translate('notifications.titles.error'),
        text: 'Uploaded image must be one of the following formats: PNG, JPG, JPEG and BMP',
        confirmButtonText: translate('notifications.choices.close'),
      });
    } else {
      if (event.target) event.target.value = ''
      Swal.fire({
        imageUrl: errorBigIcon,
        title: translate('notifications.titles.error'),
        text: 'Please upload a photo smaller than 1 Mb',
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  };

  const closeCropper = useCallback((image?: string) => {
    if (fileInput?.current?.value) {
      fileInput.current.value = '';
    }
    if (image) {
      setCropedImg(image);
    }
    setCropperNotification({
      image: '',
      isOpen: false,
    });
  }, []);

  const unsubscribe = async (isSubscribe) => {
    setLoading(true)
    try {
      const unsub = await dispatch(unsubscribeMailProfile(isSubscribe)).unwrap();

      setLoading(false)
      await Swal.fire({
        title: translate('notifications.titles.success'),
        text: translate('forms.sign_up.subscribe.change') || unsub.message,
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton:false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000,
      });
    } catch (err) {
      const error = err as IError;
      setLoading(false)
      await Swal.fire({
        title: translate('notifications.titles.error'),
        text: decoratePopUpMessage(error.error as string),
        imageUrl: errorBigIcon,
        confirmButtonText: translate('notifications.choices.close'),
      });
    }
  }

  return (
    <div className="user-info-block-container">
      <div className="block-label">
        <div className="block-label-title">Basic Information</div>
        <div className="block-label-desc">Your basic account details that identify you in our system.</div>
      </div>

      <form className="user-info-form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row col-2">
          <FormControl>
            <div className="label-block">{translate('forms.common.first_name')}</div>
            <TextField
              required
              placeholder={translate('forms.common.first_name')}
              id="first-name"
              name="firstName"
              onChange={(e) => {
                handleChange(e, 'firstName');
              }}
              value={defaultForm.firstName || ''}
              error={!!errors.firstName}
            />
            {errors.firstName &&
            <ValidationErrorBlock errorMessage={errors.firstName.message as string} />
            }
          </FormControl>
          <FormControl>
            <div className="label-block">{translate('forms.common.last_name')}</div>
            <TextField
              placeholder={translate('forms.common.last_name')}
              required
              id="last-name"
              name="lastName"
              onChange={(e) => {
                handleChange(e, 'lastName');
              }}
              value={defaultForm.lastName || ''}
              error={!!errors.lastName}
            />
            {errors.lastName &&
            <ValidationErrorBlock errorMessage={errors.lastName.message as string} />
            }
          </FormControl>
        </div>
        <div className="form-row col-2">
          <FormControl>
            <div className="label-block">{translate('forms.common.email')}</div>
            <TextField
              placeholder={translate('forms.common.email')}
              id="email"
              type="email"
              disabled
              value={defaultForm?.email}
            />
          </FormControl>
        </div>
        <div className="form-row col-2">
          <FormControl>
            <div className="label-block">
              Profile photo
            </div>
            <div className="avatar-wrapper">
              <div className="p-relative">
                <img
                  loading="lazy"
                  className={`user-avatar ${!user?.picture ? 'default' : ''}`}
                  src={imageCropped}
                  onClick={() => fileInput?.current && fileInput?.current.click()}
                />
              </div>
              <div className='avatar-actions'>
                <div className="action-row">
                  <Button
                    type="button"
                    variant="outlined"
                    color="primary"
                    mr={4}
                    onClick={() => fileInput?.current && fileInput?.current.click()}
                  >
                    Upload New Photo
                  </Button>
                  {/*<Button*/}
                  {/*  type="button"*/}
                  {/*  variant="outlined"*/}
                  {/*  color="warning"*/}
                  {/*  disabled*/}
                  {/*>*/}
                  {/*  Remove*/}
                  {/*</Button>*/}
                </div>
                <div className='max-size'>
                  Max file size is 1 MB
                </div>
              </div>
              <input ref={fileInput} className="file-input" type="file" accept="image/*" onChange={onChange} />
            </div>
          </FormControl>
        </div>
        <div className="action-row">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid || isSend || (user?.name === defaultForm.firstName && user?.family_name === defaultForm.lastName)}
          >
            {translate('forms.common.save_info')}
          </Button>
        </div>
      </form>

      <ImageCropper
        isOpen={cropper.isOpen}
        image={cropper.image}
        close={closeCropper}
      />


    </div>
  );
};

export default UserInfoForm;
