import {IUser} from "./models";

const UserTestInfo: IUser = {
  sub: 'non',
  created_at: 1680166701578,
  email: 'work@backuplabs.com',
  name: 'backuplabs',
  family_name: 'backuplabs',
  hadEverAnySubscriptions: true,
  connectedExtStorage: [],
  isSubscribedToMail: false,
  paymentHistory: [{
    amount_received: 0,
    currency: 'string',
    amount_captured: 0,
    created: 0,
    last4: 'string',
    paid: true,
    status: 'string',
    product_id: 'string',
    receipt_url: 'string',
    invoice_id: 'string',
    invoice_pdf: 'string'
  }],
  company: {
    name: 'string',
    address: {
      country: 'string',
      state: 'string'
    },
    tax_details: [{
      type: 'string',
      value: 'string'
    }]
  }
}

export default UserTestInfo
