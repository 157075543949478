import {useEffect} from "react";
import {useAppDispatch} from "../store/hooks";
import {logout} from "../store/actions";
import moment from 'moment';
import {useLocation} from "react-router-dom";

const events = [
  "load",
  "click",
  "scroll",
  "keypress",
];

const AppLogout = ({ children }) => {
  const params = new URLSearchParams(useLocation().search);
  const timerOff = params.get('timeoff')

  if (timerOff !== null) {
    localStorage.setItem('timeOff', 'true')
  }

  const dispatch = useAppDispatch();
  let timer;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      if (localStorage.getItem('tMode') && moment().isSameOrAfter(localStorage.getItem('tMode'))) {
        resetTimer();
        Object.values(events).forEach((item) => {
          window.removeEventListener(item, resetTimer);
        });
        logoutAction();
      } else {
        // localStorage.setItem('tMode', moment().add(10, 'm').toJSON())
        resetTimer();
        handleLogoutTimer();
      }
    }, 600000); // 600000ms = 10min. You can change the time.
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        localStorage.setItem('tMode', moment().add(10, 'm').toJSON())
        if (window.location.pathname.indexOf('auth') === -1 && window.location.pathname.indexOf('checkout') === -1 && window.location.pathname.indexOf('verify-email') === -1) {
          localStorage.setItem('lastPage', window.location.pathname + window.location.search)
        } else if (window.location.pathname.indexOf('checkout') !== -1) {
          localStorage.removeItem('lastPage')
        }
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  const logoutAction = () => {
    if (window.location.pathname.indexOf('auth') === -1) {
      if (process.env.NODE_ENV !== 'development' && !localStorage.getItem('timeOff')){
        document.querySelector('.swal2-container')?.remove()
        dispatch(logout(true));
      }
    }
  };

  return children;
};

export default AppLogout;
