import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../StyledComponents';
import './style.scss';
import {Plus} from "react-feather";

interface INoBackupsBlockProps {
  action: () => void
  actionText: string
  altAction?: () => void
  altActionText?: string
  platformItem: string
}

const NoItemsBlock: FunctionComponent<INoBackupsBlockProps> = ({
                                                                 action,
                                                                 actionText,
                                                                 altAction,
                                                                 altActionText
                                                                 // platformItem
}: INoBackupsBlockProps) => {
  const { t: translate } = useTranslation();

  return (
    <div className="data-block-sub">
      <div className="subtitle">
        {translate('views.platform_pages.no_backups')}
      </div>
      <Button variant="contained" color="primary" onClick={action}>
        <Plus className="feather-icon-in-button"/> {actionText}
      </Button>
      {altAction && altActionText && (
        <Button variant="contained" color="primary" onClick={altAction} mt={4}>
          <Plus className="feather-icon-in-button"/> {altActionText}
        </Button>
      )}
    </div>
  );
};

export default (NoItemsBlock);
