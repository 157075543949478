import {
  EPlatformsNames,
  EStripeSubStatus,
  ESubscriptionPlans,
  ETrialStatus,
  SubscriptionMockData,
} from '../models/consts';
import { SubPlanInfo } from '../models/models';

export default function getSubInfo(trialState, subInfo, trialDays, platformName, trialQuantity, trialPlanQuantity): SubPlanInfo {
  switch (platformName) {
    case EPlatformsNames.GITHUB:
    case EPlatformsNames.GITLAB:
      return getGitSubInfo(trialState, subInfo, trialDays, trialQuantity, trialPlanQuantity);
    case EPlatformsNames.TRELLO:
    case EPlatformsNames.JIRA:
    case EPlatformsNames.NOTION:
      return getTrelloSubInfo(trialState, subInfo, trialDays, trialQuantity, trialPlanQuantity);
    default:
      return SubscriptionMockData[ESubscriptionPlans.NO_SUB];
  }
}

function getGitSubInfo(trialState, subInfo, trialDays, trialQuantity, trialPlanQuantity?) {
  if (trialState === ETrialStatus.STARTED && subInfo?.status !== EStripeSubStatus.ACTIVE || trialState === ETrialStatus.EXPIRED && !subInfo) {
    return {
      ...SubscriptionMockData[ESubscriptionPlans.TRIAL],
      periodHours: trialDays || 0,
      quantity: trialQuantity || 0,
      planQuantity: trialPlanQuantity || 0,
      isPaid: true,
    };
  } else if (subInfo) {
    return {
      ...SubscriptionMockData[ESubscriptionPlans.SUBSCRIPTION],
      name: subInfo?.planName || 'Essential',
      isPaid: subInfo.status === EStripeSubStatus.ACTIVE,
      cost: `$${subInfo?.cost}/${subInfo?.isAnnual ? 'y' : 'm'}`,
      quantity: subInfo.quantity || 0,
      planQuantity: subInfo.planQuantity || 0,
    };
  }

  return SubscriptionMockData[ESubscriptionPlans.NO_SUB];
}

function getTrelloSubInfo(trialState, subInfo, trialDays, trialQuantity, trialPlanQuantity?) {
  if (trialState === ETrialStatus.STARTED && subInfo?.status !== EStripeSubStatus.ACTIVE || trialState === ETrialStatus.EXPIRED && !subInfo) {
    return {
      ...SubscriptionMockData[ESubscriptionPlans.TRIAL],
      periodHours: trialDays || 0,
      quantity: trialQuantity || 0,
      planQuantity: trialPlanQuantity || 0,
      isPaid: true,
    };
  } else if (subInfo) {
    return {
      ...SubscriptionMockData[ESubscriptionPlans.SUBSCRIPTION],
      name: subInfo?.planName || 'Essential',
      isPaid: subInfo.status === EStripeSubStatus.ACTIVE,
      cost: `$${subInfo?.isAnnual ? subInfo.cost * 12 + '/yr.' : subInfo.cost + '/mj.'}`,
      quantity: subInfo.quantity || 0,
      planQuantity: subInfo.planQuantity || 0,
    };
  }

  return SubscriptionMockData[ESubscriptionPlans.NO_SUB];
}
