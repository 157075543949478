import React from 'react';
import { useTranslation } from 'react-i18next';
import SidebarNavList from './SidebarNavList';
import { SectionTitle as Title } from './styledComponents';
import { SidebarNavSectionProps } from './types';
import {Plus} from "react-feather";
import {useNavigate} from "react-router-dom";

const SidebarNavSection: React.FC<SidebarNavSectionProps> = ({
  title,
  pages,
  bigMenu,
  onClose,
  component: Component = 'nav',
  ...rest
}: SidebarNavSectionProps) => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  return (
    <Component {...rest}>
      {title && bigMenu && (
        <div className='sidebar-block-title'>
          <Title variant="subtitle2">{translate(title)}</Title>
          <Plus className='feather-icon-sidebar' onClick={()=>navigate('/app-connector')}/>
        </div>
      )}
      <SidebarNavList bigMenu={bigMenu} pages={pages} depth={0} onClose={onClose}/>
    </Component>
  );
};

export default SidebarNavSection;
