import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Theme
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Button, Typography} from '../../components/StyledComponents';
import styled, {ThemeProps, withTheme} from 'styled-components';
import {useTranslation} from "react-i18next";
import Chart from "react-chartjs-2";
import {orange} from "@mui/material/colors";
import {ChartData} from "chart.js";
import {AlertTriangle} from "react-feather";
import {useNavigate} from "react-router-dom";
import {EPlatformsNames} from "../../models/consts";

const Card = styled(MuiCard)<{ illustration?: string }>`
  position: relative;
  margin-bottom: 24px;
  border: 1px solid #E5E7EB;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  height: 392px;
  color: ${(props) => props.theme.palette.primary.main};
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding: 24px;
    box-sizing: border-box;
  }
`;

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
  margin-bottom: 32px;

  canvas {
    z-index: 1;
    position: absolute;
  }
`;
const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  transform: translateY(-50%);
  z-index: 0;
`;
const TableRow = styled(MuiTableRow)`
  height: 42px;

  div {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-right: 5px;
    float: left;
    margin-top: 6px;
  }

  &:first-child {
    div {
      background: #6D4CF0;
    }
  }

  &:last-child {
    div {
      background: #6BF1FB;
    }

    th, td {
      border: 0;
    }
  }
`;

const TableCell = styled(MuiTableCell)`
  padding: 0 4px;
  font-weight: 400;
  font-size: 14px;
  color: #6B7280;
`;

const GreyText = styled.span`
  color: #6B7280;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize
`;

type StatsProps = {
  title: string;
  platform: string;
  plan: string;
  itemsAmount: Record<
    EPlatformsNames,
    | { allItems: number; backupedItems: number }
    | { repos: { allItems: number; backupedItems: number }; gists: { allItems: number; backupedItems: number } }
    | {
    projects: { allItems: number; backupedItems: number };
    snippets: { allItems: number; backupedItems: number };
  }
  >
  isTrialEnd: boolean;
  isConnect: boolean;
};

const Stats: React.FC<StatsProps> = ({
                                       title,
                                       platform,
                                       itemsAmount,
                                       theme,
                                       isTrialEnd,
                                       plan,
                                       isConnect
                                     }: ThemeProps<Theme>) => {
  const {t: translate} = useTranslation();
  const [pie, setPie] = useState<ChartData>();
  const navigate = useNavigate();

  useEffect(() => {
    const labels: string[] = [' Protected', ' Unprotected'] // тут будет конфликт, данное изменение нужно, чтобы нарисовать кружок когда нет данных по объемам, коммент можно удалить после
    let size: number[]
    if (itemsAmount && !isNaN(itemsAmount.backupedItems) && !isNaN(itemsAmount.allItems)) {
      size = [itemsAmount.backupedItems, itemsAmount.allItems - itemsAmount.backupedItems]
    } else if (itemsAmount && platform === EPlatformsNames.GITHUB) {
      const backupedItems = itemsAmount.gists.backupedItems + itemsAmount.repos.backupedItems
      size = [backupedItems, itemsAmount.gists.allItems + itemsAmount.repos.allItems - backupedItems]
    } else if (itemsAmount && platform === EPlatformsNames.GITLAB) {
      const backupedItems = itemsAmount.snippets.backupedItems + itemsAmount.projects.backupedItems
      size = [backupedItems, itemsAmount.snippets.allItems + itemsAmount.projects.allItems - backupedItems]
    } else {
      size = [0, 1]
    }
    setPie({
      labels: labels,
      datasets: [
        {
          data: size,
          backgroundColor: [
            '#6D4CF0',
            '#6BF1FB',
            orange[500],
            theme.palette.grey[200],
          ],
          borderRadius: 50,
          borderColor: theme.palette.background.paper,
        },
      ],
    })
  }, [itemsAmount])

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    radiusBackground: {
      color: '#6BF1FB'
    },
    cutout: '80%',
  };
  const allItems = platform === EPlatformsNames.GITHUB ? itemsAmount?.gists?.allItems + itemsAmount?.repos?.allItems : platform === EPlatformsNames.GITLAB ? itemsAmount?.projects?.allItems + itemsAmount?.snippets?.allItems : itemsAmount?.allItems
  const allBackupedItems = platform === EPlatformsNames.GITHUB ? itemsAmount?.gists?.backupedItems + itemsAmount?.repos?.backupedItems : platform === EPlatformsNames.GITLAB ? itemsAmount?.projects?.backupedItems + itemsAmount?.snippets?.backupedItems : itemsAmount?.backupedItems

  const protectedFirstItems = platform === EPlatformsNames.GITHUB ? `${translate(`views.bulk_restore.itemName.github.many`)}: ${itemsAmount?.repos?.backupedItems}` : platform === EPlatformsNames.GITLAB ? `${translate(`views.bulk_restore.itemName.gitlab.many`)}: ${itemsAmount?.projects?.backupedItems}` : itemsAmount ? `${translate(`views.bulk_restore.itemName.${platform}.many`)}: ${itemsAmount?.backupedItems}` : ''
  const protectedSecondItems = platform === EPlatformsNames.GITHUB ? `${translate(`views.bulk_restore.itemName.github_sub.many`)}: ${itemsAmount?.gists?.backupedItems}` : platform === EPlatformsNames.GITLAB ? `${translate(`views.bulk_restore.itemName.gitlab_sub.many`)}: ${itemsAmount?.snippets?.backupedItems}` : ''

  const unprotectedFirstItems = platform === EPlatformsNames.GITHUB ? `${translate(`views.bulk_restore.itemName.github.many`)}: ${itemsAmount?.repos?.allItems - itemsAmount?.repos?.backupedItems}` : platform === EPlatformsNames.GITLAB ? `${translate(`views.bulk_restore.itemName.gitlab.many`)}: ${itemsAmount?.projects?.allItems - itemsAmount?.projects?.backupedItems}` : itemsAmount ? `${translate(`views.bulk_restore.itemName.${platform}.many`)}: ${itemsAmount?.allItems - itemsAmount?.backupedItems}` : ''
  const unprotectedSecondItems = platform === EPlatformsNames.GITHUB ? `${translate(`views.bulk_restore.itemName.github_sub.many`)}: ${itemsAmount?.gists?.allItems - itemsAmount?.gists?.backupedItems}` : platform === EPlatformsNames.GITLAB ? `${translate(`views.bulk_restore.itemName.gitlab_sub.many`)}: ${itemsAmount?.snippets?.allItems - itemsAmount?.snippets?.backupedItems}` : ''

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" mb='24px'>
          {title} Protection
        </Typography>
        <ChartWrapper>
          <DoughnutInner>
            <Typography variant="h2">
              {
                allBackupedItems > 0 ? Math.round((allBackupedItems) / allItems * 100) : 0}%
            </Typography>
          </DoughnutInner>
          {pie && (
            <Chart type="doughnut" data={pie} options={options}/>
          )}
        </ChartWrapper>
        <Table style={{marginTop: (isTrialEnd || !isConnect) ? '-8px' : '0'}}>
          {
            isConnect && !isTrialEnd && itemsAmount && ((!isNaN(itemsAmount.backupedItems) && !isNaN(itemsAmount.allItems)) ||
              platform === EPlatformsNames.GITHUB || platform === EPlatformsNames.GITLAB) ? (
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <div/>
                      Protected
                    </TableCell>
                    <TableCell align="right">
                      <GreyText>
                        {
                          platform === EPlatformsNames.GITHUB || platform === EPlatformsNames.GITLAB ?
                            `${protectedFirstItems} / ${protectedSecondItems}` :
                            protectedFirstItems
                        }
                      </GreyText>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <div/>
                      Unprotected
                    </TableCell>
                    <TableCell align="right">
                      <GreyText>
                        {
                          platform === EPlatformsNames.GITHUB || platform === EPlatformsNames.GITLAB ?
                            `${unprotectedFirstItems} / ${unprotectedSecondItems}` :
                            unprotectedFirstItems
                        }
                      </GreyText>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) :
              <TableBody className='stat-badges'>
                <TableRow>
                  <TableCell align="left" className='badge-left'>
                    <div className='badge-left-ico'>
                      <AlertTriangle className="feather-icon-in-button"/>
                    </div>
                    {isConnect ? `Your ${plan ? 'free trial' : 'subscription'} has expired.` : 'Account is not connected'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" className='badge-right'>
                    <Button variant="contained" color="primary" fullWidth
                            onClick={() => {
                              if (isConnect) {
                                localStorage.setItem('checkout-platform', platform)
                                navigate('/checkout');
                              } else {
                                navigate('app-connector')
                              }
                            }}
                            style={{minWidth: '120px'}}
                    >
                      {!isConnect ? translate('subscriptions.connect_now') : translate('notifications.choices.subscribe')}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
          }
        </Table>
      </CardContent>
    </Card>
  );
};

export default withTheme(Stats);
