import {Box, CircularProgress} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {ArrowLeft, ExternalLink, Info} from 'react-feather';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {EPaymentRequiredType} from '../../../models/consts';
import {IPaymentRequiredPoppup} from '../../../models/inner-models';
import {IBackupRow} from '../../../views/PlatformPages/types';
import NoBackupsBlock from '../../Blocks/NoBackupsBlock/NoBackupsBlock';
import PaymentRequiredPoppup from '../../Dialogs/PaymentRequiredPoppup/PaymentRequiredPoppup';
import {Button, Typography} from '../../StyledComponents';
import '../style.scss';
import './styles.scss';
import ActionsTable from '../PlatformActionsTable/ActionsTable';
import {useTranslation} from "react-i18next";
import Platform from '../../../models/platforms-data';
import {BadgesNotionUpd} from "../../Badges/Badges";
import JiraHowTo from "../../../views/PlatformPages/JiraPages/JiraBoardsListView/JiraHowTo/JiraHowTo";
import {getUserProfileName} from "../../../store/actions";
import {useAppDispatch} from "../../../store/hooks";
import {useWebSocket} from "../../../contexts/WebSocketContext";
import { updateActionsState } from '../../../store/reducers/backups';
import {EWebsocketMethods} from "../../../constants";

const useStyles = makeStyles({
    backupsBlock: {
      width: '100%',
    },
    fullWidthBlock: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    spinner: {
      color: '#684EE7',
    },
  },
);

interface IPlatformActivityPageBlockProps {
  platformName: string;
  loading: boolean;
  downloadZipCallback: (item: IBackupRow) => void;
  restoreItemCallback: (versions: string, name: string, key: string, emails: string[], organizationId?:string) => void;
  rows: IBackupRow[];
  paymentPopup: IPaymentRequiredPoppup;
  paymentRequiredState?: EPaymentRequiredType;
  closeTrialPopupCallback?: ((approved?: (boolean | undefined)) => void);
  appWasDeleted?: boolean
  total: number;
  page: number;
  setPage: (number) => void;
  isLoading: boolean;
  canRestore: boolean;
}

const PlatformActionsPage: FunctionComponent<IPlatformActivityPageBlockProps> = ({
  loading,
  rows,
  downloadZipCallback,
  restoreItemCallback,
  paymentPopup,
  paymentRequiredState,
  closeTrialPopupCallback,
  platformName, appWasDeleted,
  total, page,
  setPage, isLoading,canRestore
}: IPlatformActivityPageBlockProps) => {
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const data = searchParams.get('name')?.split('::') || ''
  const [isOpenTutorial, setIsOpenTutorial] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [profiles, setProfiles] = useState<Record<string, any>>({});
  const isSubPlatform = platformName === 'gitlab' ? localStorage['gitlabType'] === 'snippet' : platformName === 'github' ? Number(localStorage['githubUser']) === 0 : false
  const { onMessage } = useWebSocket();
  const currentPageRef = useRef(page);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resProf = await dispatch(getUserProfileName()).unwrap()
        if (resProf) {
          setProfiles(resProf)
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();

    onMessage((data) => {
      if(![EWebsocketMethods.itemsAddedCountChange,EWebsocketMethods.updateDashboardPlatformItemsAmount].includes(data.method)){
      dispatch(updateActionsState({...data, page: currentPageRef.current}))
      }
    });

  }, [])

  useEffect(() => {
    currentPageRef.current = page;
  }, [page]);

  return (
    <div id="platform-content">
      {platformName === 'notion' && (
        <BadgesNotionUpd key='actionPage' platformName='notion'/>
      )}
      <div className="header-block actions-header">
        <div className='actions-header-left' onClick={() => navigate(`/platform/dashboard/${platformName}`)}>
          <ArrowLeft className="arrow-icon"/>
          {isSubPlatform ? (platformName === 'github' ? 'Back to All Gists' : 'Back to All Snippets') : translate(`views.platform_pages.actions_list.backto_${platformName}`)}
        </div>
        <div className='actions-header-right'>
          <Button variant="outlined" color="primary"
                  onClick={() => {
                    if (profiles[platformName]) {
                      switch (platformName) {
                        case 'github':
                          window.open(`https://github.com/${profiles.github[Object.keys(profiles.github)[0]]}`, '_blank')
                          break;
                        case 'gitlab':
                          window.open(`https://gitlab.com/dashboard/projects`, '_blank')
                          break;
                        case 'trello':
                          window.open(`https://trello.com/u/${profiles.trello}/boards`, '_blank')
                          break;
                        case 'jira':
                          window.open(`https://${profiles.jira}.atlassian.net/jira/your-work`, '_blank')
                          break;
                        case 'notion':
                        default:
                          window.open(Platform[platformName].url, '_blank')
                          break
                      }
                    } else {
                      window.open(Platform[platformName].url, '_blank')
                    }
                  }}
          >
            {translate('common.buttons.visit')} {Platform[platformName].title}
            <ExternalLink className="feather-icon-in-button icon-ml"/>
          </Button>

          {platformName === 'jira' && (
            <Button variant='contained' color='secondary' onClick={() => setIsOpenTutorial(true)} ml={4}>
              How To Restore
            </Button>
          )}
        </div>
      </div>
      <div className="header-block actions-header">
        <Typography variant="h3" gutterBottom display="inline" className="item-name-header">
          {data[1] && <span className='item-private-type'>{data[1]}</span>}
          {data[0]}
        </Typography>
      </div>
      <div className='header-block action-page-platform-logo'>
        <img src={Platform[platformName]?.smallLogo} className="platform-logo" loading="lazy"/>
        {Platform[platformName]?.title}
      </div>
      <Box sx={{width: '100%'}}>
        <div className="info-block">
          <>
            <div className={classes.backupsBlock}>
              <div id="platform-backups">
                {loading ?
                  <div className="spinner-wrapper">
                    <CircularProgress className={classes.spinner}/>
                  </div> :
                  <>
                    {!rows.length ?
                      <NoBackupsBlock/> :
                      <div className="backups-table">
                        <ActionsTable
                          rows={rows} downloadZip={downloadZipCallback} name={data[0]}
                          restoreItem={restoreItemCallback} platformName={platformName}
                          type={paymentRequiredState}
                          appWasDeleted={appWasDeleted}
                          total={total}
                          page={page}
                          setPage={setPage}
                          isLoading={isLoading}
                          openTutorial={()=>setIsOpenTutorial(true)}
                          canRestore={canRestore}
                        />
                      </div>
                    }
                  </>
                }
                <PaymentRequiredPoppup
                  isOpen={paymentPopup.isOpen}
                  closeNotification={closeTrialPopupCallback}
                  type={paymentRequiredState}
                  platformName={paymentPopup.platformName}
                />
              </div>
            </div>
          </>
        </div>
      </Box>
      {platformName === 'jira' && (
        <JiraHowTo isOpen={isOpenTutorial} closeNotification={() => setIsOpenTutorial(false)}/>
      )}
    </div>
  );
};

export default (PlatformActionsPage);
