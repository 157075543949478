import {Card as MuiCard, CardContent as MuiCardContent, CircularProgress, MenuItem, Select} from '@mui/material';
import React, {FunctionComponent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Typography} from '../../../components/StyledComponents';
import withRedirectAfterLogout from '../../../utils/withRedirectAfterLogout';
import ChangePasswordForm from './ChangePasswordForm/ChangePasswordForm';
import ChangePaymentMethodForm from './ChangePaymentMethodForm/ChangePaymentMethodForm';
import './style.scss';
import UserInfoForm from './UserInfoForm/UserInfoForm';
import {getCloudStorageUrl, removeUsersPaymentTax, updBillingAddress} from "../../../store/actions";
import {IBillingAddressPayload, IError} from "../../../models/inner-models";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {IUser} from "../../../models/models";
import {getUserSelector, loadingUser, stripePaymentMethod} from "../../../store/selectors";
import styled from 'styled-components';
import BillingForm from "../../../components/BillingForm/BillingForm";
import {errorBigIcon, iconNotifyWarningInfo, toastSuccess} from "../../../assets/icons/icons";
import Swal from "sweetalert2";
import TwoFactorAuthentication from "./TwoFactorAuthentication/TwoFactorAuthentication";
import {decoratePopUpMessage} from "../../../utils/popUpTextDecorator";
import ExternalStorage from "./ExternalStorage/ExternalStorage";
import EmailManage from "./EmailManage/EmailManage";

const Card = styled(MuiCard)`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(6)};
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  width: 100%;
`;

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding: 24px;
    box-sizing: border-box;
  }
`;

const UserSettings: FunctionComponent = () => {
  const {t: translate} = useTranslation();
  const dispatch = useAppDispatch();
  const userLoading = useAppSelector(loadingUser);
  const user: IUser | null = useAppSelector(getUserSelector);
  const [loading, setLoading] = useState<boolean>(false);
  const [billingDet, setBillingDet] = useState<IBillingAddressPayload | null>();
  const defaultPaymentMethod = useAppSelector(stripePaymentMethod);

  const deepEqual = (obj1, obj2) => {
    if (typeof obj1 !== typeof obj2) {
      return false;
    }
    if (obj1 === obj2) {
      return true;
    }
    if (typeof obj1 === 'object') {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        if (!deepEqual(obj1[key], obj2[key])) {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (!userLoading && user && user.company?.address) {
      const billData = {
        company_details: {
          name: user.company.name || '',
          city: user.company.address.city || '',
          country: user.company.address.country || '',
          line1: user.company.address.line1 || '',
          line2: user.company.address.line2 || '',
          state: user.company.address.state || '',
          postal_code: user.company.address.postal_code || ''
        }
      }
      if (!!user.company.tax_details.length) {
        if (user.company.tax_details.length > 1) {
          dispatch(removeUsersPaymentTax(user.company.tax_details[0].id as string))
        }
        const revTax = [...user.company.tax_details].reverse()
        billData['tax_details'] = {
          type: revTax[0].type,
          value: revTax[0].value
        }
      }

      if (!billingDet || billingDet && !deepEqual(billingDet, billData) && !loading) {
        setBillingDet({...billData})
        setLoading(false)
      }
    }
  }, [userLoading, user, loading])

  const updBillAddress = async (newBillingDet) => {
    setLoading(true)
    if (newBillingDet && defaultPaymentMethod) {
      const res = await dispatch(updBillingAddress(newBillingDet)).unwrap()
        .catch((err) => {
          console.log(err)
          const error = err as IError;
          Swal.fire({
            title: translate('notifications.titles.error'),
            text: decoratePopUpMessage(error.message || error.error as string),
            imageUrl: errorBigIcon,
          });
          setLoading(false)
        })
        .finally(()=>setLoading(false))
      Swal.fire({
        title: translate('notifications.titles.success'),
        text: 'Billing address was successfully updated',
        toast: true,
        position: 'top-end',
        timerProgressBar: true,
        showConfirmButton:false,
        showCloseButton: true,
        imageUrl: toastSuccess,
        timer: 3000
      });
      // setLoading(false)
      return res
    } else {
      Swal.fire({
        title: translate('notifications.titles.warning'),
        text: 'First you need to add a payment method.',
        imageUrl: iconNotifyWarningInfo,
      });
    }
    setLoading(false)
    return true
  }

  return (
    <div id="user-settings-page">
      {loading &&
        <div className="spinner-wrapper">
          <CircularProgress
            // className={classes.spinner}
          />
        </div>
      }
      <div className="header-block">
        <Typography variant="h3" gutterBottom display="inline">
          {translate('common.default_pages.profile')}
        </Typography>

        <Select
          value='more'
          className='profile-select'
          MenuProps={{id: 'sub-profile-select'}}
        >
          <MenuItem value='more' disabled sx={{display: 'none'}}>
            Actions
          </MenuItem>
          <MenuItem
            value='history-list'
            onClick={() => window.open('https://support.backuplabs.io/hc/en-gb', '_blank')}
          >
            Get {translate('common.default_pages.support')}
          </MenuItem>
          {/*<MenuItem*/}
          {/*  value='cancel-sub'*/}
          {/*  sx={{color: '#EF4444'}}*/}
          {/*  onClick={() => cancelOneSub(params.row.platformName)}*/}
          {/*  disabled={params.row.status !== 'active' || params.row.plan === 'Trial' || params.row.isCancel}*/}
          {/*>*/}
          {/*  {translate('subscriptions.cancel_subscription')}*/}
          {/*</MenuItem>*/}
        </Select>
      </div>
      <Card>
        <CardContent>
          <UserInfoForm setLoading={setLoading}/>
          <EmailManage setLoading={setLoading}/>
          <ExternalStorage/>

          <ChangePasswordForm/>
          <TwoFactorAuthentication/>
          <BillingForm
            setData={setBillingDet}
            data={billingDet}
            userPage={true}
            upd={updBillAddress}
          />

          <ChangePaymentMethodForm/>

        </CardContent>
      </Card>

      <div className="user-settings-block">

        <div className="change-payment-method-block">
        </div>

      </div>
    </div>
  );
};

export default withRedirectAfterLogout()(UserSettings);
