import React from 'react';
import {Navigate} from 'react-router-dom';

import Auth from './layouts/Auth';
import Dashboard from './layouts/Dashboard';

import Login from './views/AuthPages/Login/Login';
import SignUp from './views/AuthPages/SignUp/SignUp';
import ConfirmedSignup from './views/AuthPages/ConfirmedSignup/ConfirmedSignup';
import ResetPassword from './views/AuthPages/ResetPassword/ResetPassword';
import DashboardPage from './views/Dashboard/DashboardPage';

import GithubActivityPage from './views/PlatformPages/GithubPages/GithubActivityView';
import GithubPlatformSettings from './views/PlatformPages/GithubPages/GithubReposListView';
import GithubUserSettings from './views/PlatformPages/GithubPages/GithubUserAddEditView';

import GitlabActivityPage from './views/PlatformPages/GitlabPages/GitlabActivityView';
import GitlabPlatformSettings from './views/PlatformPages/GitlabPages/GitlabProjectsListView';
import GitlabUserSettings from './views/PlatformPages/GitlabPages/GitlabUserAddEditView';

import JiraActivityPage from './views/PlatformPages/JiraPages/JiraActivityView';
import JiraPlatformSettings from './views/PlatformPages/JiraPages/JiraBoardsListView';
import JiraUserSettings from './views/PlatformPages/JiraPages/JiraUserAddEditView';

import TrelloActivityPage from './views/PlatformPages/TrelloPages/TrelloActivityView';
import TrelloPlatformSettings from './views/PlatformPages/TrelloPages/TrelloBoardsListView';
import TrelloUserSettings from './views/PlatformPages/TrelloPages/TrelloUserAddEditView';

import Subscription from './views/UserPages/Subscription/Subscription';
// import AppConnectors from './views/UserPages/AppConnectors/AppConnectors';
import UserSettings from './views/UserPages/UserSettings/UserSettings';
import ContactPage from './views/Contact/ContactPage';
import TrelloConnectSecret from './views/Other/TrelloSecretPage';
import PageNotFound from './views/PageNotFound/PageNotFound';
import Unsubscribe from "./views/AuthPages/Unsubscribe/Unsubscribe";
import AuditTrail from "./views/UserPages/AuditTrail/AuditTrail";
import AddApp from "./views/UserPages/AddApp/AddApp";
import BulkRestore from "./views/UserPages/BulkRestore/BulkRestore";
import Checkout from "./views/UserPages/Checkout/Checkout";
import PaymentHistory from "./views/UserPages/PaymentHistory/PaymentHistory";
import NotionPagesList from "./views/PlatformPages/NotionPage/NotionPagesListView";
import NotionActivityPage from "./views/PlatformPages/NotionPage/NotionActivityView";

const routes = [
  // {
  //   path: '/',
  //   element: <Dashboard />,
  //   children: [
  //     {
  //       path: '',
  //       element: <UserSettings />,
  //     }],
  // },
  {
    path: '/',
    element: <Dashboard/>,
    children: [{
      path: '',
      element: <DashboardPage/>,
    }],
  },
  {
    path: 'contact',
    element: <Dashboard/>,
    children: [{
      path: '',
      element: <ContactPage/>,
    }],
  },
  {
    path: 'verify-email',
    element: <Auth/>,
    children: [{
      path: '',
      element: <ConfirmedSignup/>,
    }],
  },
  {
    path: 'subscribeToMail',
    element: <Auth/>,
    children: [{
      path: '',
      element: <Unsubscribe/>,
    }],
  },
  {
    path: 'auth',
    element: <Auth isSignUp={true}/>,
    children: [
      {
        path: 'sign-up',
        element: <SignUp/>,
      },
    ],
  },
  {
    path: 'auth',
    element: <Auth/>,
    children: [
      {
        path: '',
        element: <Login/>,
      },
      {
        path: 'login',
        element: <Login/>,
      },
      {
        path: 'password-recovery',
        element: <ResetPassword/>,
      },
    ],
  },
  {},
  {
    path: 'subscriptions',
    element: <Dashboard/>,
    children: [
      {
        path: '',
        element: <Subscription/>,
      }],
  },
  // {
  //   path: 'wait',
  //   element: <Dashboard/>,
  //   children: [
  //     {
  //       path: '',
  //       element: <OnWork/>,
  //     }],
  // },
  {
    path: 'checkout',
    element: <Dashboard/>,
    children: [
      {
        path: '',
        element: <Checkout/>,
      }],
  },
  {
    path: 'audit',
    element: <Dashboard/>,
    children: [
      {
        path: '',
        element: <AuditTrail/>,
      }],
  },
  {
    path: 'payment-history',
    element: <Dashboard/>,
    children: [
      {
        path: '',
        element: <PaymentHistory/>,
      }],
  },
  {
    path: 'app-connector',
    element: <Dashboard/>,
    children: [
      {
        path: '',
        element: <AddApp/>,
      }],
  },
  {
    path: 'bulk-restore',
    element: <Dashboard/>,
    children: [
      {
        path: '',
        element: <BulkRestore/>,
      }],
  },
  {
    path: 'profile',
    element: <Dashboard/>,
    children: [
      {
        path: '',
        element: <UserSettings/>,
      }],
  },
  {
    path: 'platform',
    element: <Dashboard/>,
    children: [
      {
        path: 'backups',
        children: [
          {
            path: 'github',
            element: <GithubActivityPage/>,
          }, {
            path: 'trello',
            element: <TrelloActivityPage/>,
          }, {
            path: 'gitlab',
            element: <GitlabActivityPage/>,
          }, {
            path: 'jira',
            element: <JiraActivityPage/>,
          }, {
            path: 'notion',
            element: <NotionActivityPage/>,
          }
        ]
      },
      {
        path: 'user-settings',
        children: [
          {
            path: 'github',
            element: <GithubUserSettings/>,
          }, {
            path: 'trello',
            element: <TrelloUserSettings/>,
          }, {
            path: 'gitlab',
            element: <GitlabUserSettings/>,
          }, {
            path: 'jira',
            element: <JiraUserSettings/>,
          }],
      },
      {
        path: 'dashboard',
        children: [
          {
            path: 'github',
            element: <GithubPlatformSettings/>,
          }, {
            path: 'trello',
            element: <TrelloPlatformSettings/>,
          }, {
            path: 'gitlab',
            element: <GitlabPlatformSettings/>,
          }, {
            path: 'jira',
            element: <JiraPlatformSettings/>,
          }, {
            path: 'notion',
            element: <NotionPagesList/>,
          }
        ],
      },
    ],
  },
  {
    path: ':stage/trello/login-trello-callback',
    element: <Navigate to="/platform/dashboard/trello"/>,
  },
  {
    path: 'trello-approve-hidden',
    element: <TrelloConnectSecret/>,
  },
  {
    path: '*',
    element: <PageNotFound/>,
  },
];

export default routes;
