import { createAsyncThunk } from '@reduxjs/toolkit';
import { EApiStatuses } from '../../../constants';
import {IError, IPageActionsPayload} from '../../../models/inner-models';
import {IDownloadZip, IRestorePayload} from '../../../models/models';
import { EBackups, EPlatformNames } from '../../constants';
import { addPlatformIdentifier } from '../../functions';
import instance from '../../middlewares/api';

const getGitlabActions = createAsyncThunk(
  addPlatformIdentifier(EBackups.transferRepoData, EPlatformNames.gitlab),
  async (payload: IPageActionsPayload, thunkAPI) => {
    try {
      const resp = await instance.post(`user/get-actions?page=${payload.page}&perPage=${payload.perPage}`,
        {platform: EPlatformNames.gitlab, id: payload.itemId },{ signal: payload.signal });
      return (!resp?.data?.result || resp.data.status !== EApiStatuses.SUCCESS) ?
        thunkAPI.rejectWithValue({ error: resp?.data?.message || 'Error on data load. Try later' }) : resp.data.result;
    } catch (err) {
      const error = err as IError

      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

const restoreGitlabBackup = createAsyncThunk(
  addPlatformIdentifier(EBackups.restoreBackup, EPlatformNames.gitlab),
  async (boardRestore: IRestorePayload, thunkAPI) => {
    try {
      const resp = await instance.post('gitlab/restore-projects', boardRestore);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
      thunkAPI.rejectWithValue({error: resp?.data?.message || 'Error on data restore. Try later'}) : resp.data;
    } catch (err) {
      const error = err as IError

      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

const restoreGitlabSnippetsBackup = createAsyncThunk(
  addPlatformIdentifier(EBackups.restoreBackup, EPlatformNames.gitlab),
  async (boardRestore: IRestorePayload, thunkAPI) => {
    try {
      const resp = await instance.post('gitlab/restore-gitlab-snippets', boardRestore);
      return (!resp?.data || resp.data.status !== EApiStatuses.SUCCESS) ?
      thunkAPI.rejectWithValue({error: resp?.data?.message || 'Error on data restore. Try later'}) : resp.data;
    } catch (err) {
      const error = err as IError

      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

const downloadGitlabZip = createAsyncThunk(
  addPlatformIdentifier(EBackups.downloadZip, EPlatformNames.gitlab),
  async (repoRestore: IDownloadZip, thunkAPI) => {
    try {
      const resp = await instance.post('gitlab/gitlab-get-gzip', repoRestore);
      return (!resp?.data?.result || resp.data.status !== EApiStatuses.SUCCESS) ?
      thunkAPI.rejectWithValue({message: resp?.data?.message, status: resp?.data?.status}) : resp.data.result;
    } catch (err) {
      const error = err as IError

      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

const downloadGitlabSnippetZip = createAsyncThunk(
  addPlatformIdentifier(EBackups.downloadZip, EPlatformNames.gitlab),
  async (repoRestore: IDownloadZip, thunkAPI) => {
    try {
      const resp = await instance.post('gitlab/gitlab-snippet-get-zip', repoRestore);
      return (!resp?.data?.result || resp.data.status !== EApiStatuses.SUCCESS) ?
      thunkAPI.rejectWithValue({message: resp?.data?.message, status: resp?.data?.status}) : resp.data.result;
    } catch (err) {
      const error = err as IError

      return thunkAPI.rejectWithValue({error: error.message});
    }
  }
);

export { getGitlabActions, restoreGitlabBackup, restoreGitlabSnippetsBackup, downloadGitlabZip, downloadGitlabSnippetZip };
